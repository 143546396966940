import { Injectable } from '@angular/core';
// Must import the package once to make sure the web support initializes
import '@capacitor-community/firebase-analytics';

import { Plugins } from '@capacitor/core';
import { environment } from '@env/environment';
import { PostHogService } from '@core/services/post-hog.service';

const {FirebaseAnalytics} = Plugins;

let initialized = false;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor(private postHogService: PostHogService) {
  }

  static initAnalytics() {
    if (initialized) {
      return;
    }
    FirebaseAnalytics.initializeFirebase?.(environment.firebase.config);
    FirebaseAnalytics.setCollectionEnabled({
      enabled: true,
    });
    initialized = true;
  }

  setUserId(userId: string) {
    FirebaseAnalytics.setUserId({
      userId,
    });
  }

  setUserProps(propName: string, propValue: string) {
    if (initialized) {
      FirebaseAnalytics.setUserProperty({
        name: propName,
        value: propValue
      });
    }
  }

  logEvent(eventName: string, params = {}) {
    if (!initialized) {
      return;
    }

    const lowerCaseEvent = eventName.toLowerCase();

    this.postHogService.captureEvent(lowerCaseEvent, params);

    FirebaseAnalytics.logEvent({
      name: lowerCaseEvent,
      params,
    });
  }

  reset() {
    FirebaseAnalytics.reset();
  }

}
