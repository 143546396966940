import { gql } from 'apollo-angular';

export const UserFragment = gql`
  fragment User on User {
    userId
    email
    name
    phoneNumber
    pictureUrl
  }
`;
