import { Injectable } from '@angular/core';
import { FirebaseService, RemoteConfigValues } from '@core/services/firebase.service';
import firebase from 'firebase';
import { RemoteConfigKeys } from '@models/remote-config-keys';
import { BehaviorSubject, EMPTY, from, Observable, timer } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';
import { SentryErrorHandlerService } from '@modules/sentry/sentry-error-handler.service';
import { getDefaultConfigSettings } from '@core/services/remote-config/default-config';
import { environment } from '@env/environment';

const remoteConfig: BehaviorSubject<RemoteConfigValues> = new BehaviorSubject<RemoteConfigValues>(null);

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {

  private serviceInitialized = new BehaviorSubject<boolean>(false);

  private fetchingPromise: Promise<boolean> = Promise.resolve(true);

  constructor(private sentryErrorHandlerService: SentryErrorHandlerService) {
    // @ts-ignore
    window.frm = this;
  }

  async init() {
    if (this.isRemoteConfigServiceInitialized) {
      return;
    }

    this.loadSavedConfig();

    timer(0, this.getDefaultPollingInterval()).pipe(
      switchMap(() => from(this.getAllConfig()).pipe(
        tap((config: RemoteConfigValues) => {
          if (environment.production) {
            console.log('[RemoteConfigService][prod] Emitting new remote config!!!');
          } else {
            console.log('[RemoteConfigService][dev] Emitting new remote config: ', config);
          }
        }),
        tap((config: RemoteConfigValues) => {
          remoteConfig.next(config);
        }),
        catchError((e) => {
          // Restart the promise so next time it doesn't fail
          this.fetchingPromise = Promise.resolve(true);
          this.sentryErrorHandlerService.handleError(e, 'Error fetching Firebase Remote Config');
          return EMPTY;
        })
      )),
    ).subscribe();

    this.serviceInitialized.next(true);
  }

  private getDefaultPollingInterval(): number {
    return getDefaultConfigSettings().minimumFetchIntervalMillis;
  }

  getConfig$(): Observable<RemoteConfigValues> {
    return remoteConfig.asObservable().pipe(
      filter(config => !!config)
    );
  }

  private async updateRemoteConfig(): Promise<void> {
    await this.fetchingPromise;
    console.log('Fetching firebase remote config...');
    this.fetchingPromise = this.firebaseRemoteConfig.fetchAndActivate();
    await this.fetchingPromise;
    console.log('Firebase Remote config fetched successfully');
  }

  async getAllConfig(): Promise<RemoteConfigValues> {
    await this.updateRemoteConfig();
    return this.firebaseRemoteConfig.getAll();
  }

  getConfigAsString(config: RemoteConfigValues, configKey: RemoteConfigKeys): string {
    const value = config[configKey];
    if (!value) {
      // throw new Error(`Firebase Remote Config \'${configKey}\' not available`);
      return '';
    }
    return value.asString();
  }

  get firebaseRemoteConfig(): firebase.remoteConfig.RemoteConfig {
    return FirebaseService.getApp().remoteConfig();
  }

  async forceRefreshConfig() {
    const minimumFetchIntervalMillis = this.firebaseRemoteConfig.settings.minimumFetchIntervalMillis;
    try {
      this.firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 0;
      await FirebaseService.getApp().remoteConfig().fetchAndActivate();
      const config = this.firebaseRemoteConfig.getAll();
      console.log('[RemoteConfigService][Forced] Firebase Remote Config: ', config);
      remoteConfig.next(config);
    } catch (e) {
      this.sentryErrorHandlerService.handleError(e);
    } finally {
      this.firebaseRemoteConfig.settings.minimumFetchIntervalMillis = minimumFetchIntervalMillis;
    }
  }

  loadSavedConfig() {
    remoteConfig.next(this.firebaseRemoteConfig.getAll());
  }

  get isRemoteConfigServiceInitialized$(): Observable<boolean> {
    return this.serviceInitialized.asObservable();
  }

  get isRemoteConfigServiceInitialized(): boolean {
    return this.serviceInitialized.value;
  }

}
