import { fastdomPromised } from '@utils/dom';

const appoSafeAreaTop = '--appo-safe-area-top';
const appoSafeAreaBottom = '--appo-safe-area-bottom';

const hasNotch = async () => {
  let proceed = false;
  const div = document.createElement('div');
  if (await fastdomPromised.measure(() => CSS.supports('padding-bottom: env(safe-area-inset-bottom)'))) {
    div.style.paddingBottom = 'env(safe-area-inset-bottom)';
    proceed = true;
  } else if (await fastdomPromised.measure(() => CSS.supports('padding-bottom: constant(safe-area-inset-bottom)'))) {
    div.style.paddingBottom = 'constant(safe-area-inset-bottom)';
    proceed = true;
  }
  if (proceed) {
    await fastdomPromised.mutate(() => document.body.appendChild(div));
    const calculatedPadding = parseInt(window.getComputedStyle(div).paddingBottom, 10);
    await fastdomPromised.mutate(() => document.body.removeChild(div));
    if (calculatedPadding > 0) {
      return true;
    }
  }
  return false;
};

export const configureStyles = async (): Promise<void> => {
  try {
    if (await hasNotch()) {
      await fastdomPromised.mutate(() => document.documentElement.style.setProperty(appoSafeAreaBottom, 'var(--ion-safe-area-bottom)'));
      await fastdomPromised.mutate(() => document.documentElement.style.setProperty(appoSafeAreaTop, 'var(--ion-safe-area-top)'));
      await setNotchAttributeValue(true);
    } else {
      await setNotchAttributeValue(false);
    }
  } catch (e) {
    console.error('Error detecting and updating notch variable.');
    console.error(e);
  }
};

const setNotchAttributeValue = async (value: boolean) => {
  return fastdomPromised.mutate(() => document.querySelector('html').setAttribute('has-notch', (value).toString()));
};
