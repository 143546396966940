import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { RemoteConfigService } from '@core/services/remote-config/remote-config.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { AppMinimumVersionService } from '@core/services/app-minimum-version.service';
import { AppMinimVersionView } from '@pages/app-minimum-version/app-minimum-version-routing.names';

@Injectable({
  providedIn: 'root'
})
export class AppHasMinimumVersionGuard {

  constructor(private remoteConfigService: RemoteConfigService,
              private appMinimumVersionService: AppMinimumVersionService,
              public router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    if (state.url === AppMinimVersionView) {
      return of(true);
    }

    return this.remoteConfigService.isRemoteConfigServiceInitialized$.pipe(
      filter(hasInitialized => hasInitialized),
      switchMap(() => this.appMinimumVersionService.appMeetsMinimumVersion().pipe(
        tap(appHasMinimumVersion => {
          if (!appHasMinimumVersion) {
            this.router.navigateByUrl(AppMinimVersionView);
          }
        })
      ))
    );
  }

}
