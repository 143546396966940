import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserProfile } from '@models/user-profile';
import { IonicModule } from '@ionic/angular';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'appo-user-profile-type',
  templateUrl: './user-profile-type.component.html',
  styleUrls: ['./user-profile-type.component.scss'],
  standalone: true,
  imports: [
    IonicModule
  ]
})
export class UserProfileTypeComponent {

  UserProfile = UserProfile;

  @Input({required: true})
  isAdmin: boolean;

}
