import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Account } from '../../models/account';
import { AccountType } from '../../models/account-type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'appo-account-logo',
  templateUrl: './account-logo.component.html',
  styleUrls: ['./account-logo.component.scss']
})
export class AccountLogoComponent {

  userAccountType = AccountType.User;

  @Input()
  account: Account;

}
