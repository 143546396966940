import { Injectable } from '@angular/core';
import posthog, { Properties } from 'posthog-js';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class PostHogService {

  private serviceInitiated = false;

  init() {
    if (!this.serviceInitiated) {
      posthog.init(environment.posthog.apiKey, {
        api_host: environment.posthog.apiHost,
        enable_recording_console_log: true,
        secure_cookie: true
      });
      this.serviceInitiated = true;
      this.setDebugMode(false);
    }
  }

  identifyUser(userId: string, userPropsToSet: Properties | undefined, userPropsToSetOnce: Properties | undefined) {
    posthog.identify(
      userId, // Required. Replace 'distinct_id' with your user's unique identifier
      userPropsToSet,  // $set, optional
      userPropsToSetOnce // $set_once, optional
    );
  }

  setDebugMode(enable: boolean) {
    posthog.debug(enable);
  }

  reset() {
    posthog.reset();
  }

  captureEvent(eventName: string, properties?: Properties | null) {
    posthog.capture(eventName, properties, {
      send_instantly: true,
      transport: 'sendBeacon'
    });
  }
}
