import { Injectable } from '@angular/core';
import { RemoteConfigService } from '@core/services/remote-config/remote-config.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RemoteConfigKeys } from '@models/remote-config-keys';
import { DISABLED_MIN_VERSION, getDefaultConfig, RemoteConfigAppMinimumVersion } from '@core/services/remote-config/default-config';
import { AppoPlatformService } from '@core/services/appo-platform.service';
import { compareVersions, validate } from 'compare-versions';
import { APPOINTIA_VERSION } from '@utils/version';
import { SentryErrorHandlerService } from '@modules/sentry/sentry-error-handler.service';


@Injectable({providedIn: 'root'})
export class AppMinimumVersionService {

  constructor(private remoteConfigService: RemoteConfigService,
              private appoPlatformService: AppoPlatformService,
              private sentryErrorHandlerService: SentryErrorHandlerService) {
  }

  appMeetsMinimumVersion(): Observable<boolean> {
    return this.getAppMinimumRequiredVersionForCurrentPlatform().pipe(
      map(minimumRequiredVersion => {
        if (minimumRequiredVersion === DISABLED_MIN_VERSION) {
          return true;
        }

        if (!this.isValidVersion(minimumRequiredVersion)) {
          this.sentryErrorHandlerService.reportError(`Remote Config version is not valid: "${minimumRequiredVersion}"`);
          return true;
        }

        const compareResult = compareVersions(APPOINTIA_VERSION, minimumRequiredVersion) >= 0;
        console.log(`Comparing current version ${APPOINTIA_VERSION} against required ${minimumRequiredVersion} : ${compareResult}`);
        return compareResult;
      })
    );
  }

  getMinimumVersionConfig(): Observable<RemoteConfigAppMinimumVersion> {
    return this.remoteConfigService.getConfig$().pipe(
      map(remoteConfigValues => this.remoteConfigService.getConfigAsString(remoteConfigValues, RemoteConfigKeys.AppMinimumVersion)),
      map(appMinVersionString => {
        try {
          return JSON.parse(appMinVersionString);
        } catch (e) {
          const defaultConfig = getDefaultConfig();
          return JSON.parse((defaultConfig as Record<RemoteConfigKeys, string>)[RemoteConfigKeys.AppMinimumVersion.toString()]);
        }
      })
    );
  }

  getAppMinimumRequiredVersionForCurrentPlatform(): Observable<string> {
    return this.getMinimumVersionConfig().pipe(
      map(minimumVersionConfig => {
        if (this.appoPlatformService.isIOSPlatform) {
          return minimumVersionConfig.ios;
        }
        if (this.appoPlatformService.isAndroidPlatform) {
          return minimumVersionConfig.android;
        }
        if (this.appoPlatformService.isWebPlatform) {
          return minimumVersionConfig.web;
        }
        return DISABLED_MIN_VERSION;
      })
    );
  }

  isValidVersion(version: string): boolean {
    return validate(version);
  }

}
