import { createAction, props } from '@ngrx/store';

const actionDesc = '[Shared Link]';

export const handleSharedLink = createAction(
  `${actionDesc} Handle Shared Link`,
  props<{ slug: string }>(),
);

export const finishHandleSharedLink = createAction(`${actionDesc} Finish Handle Shared Link`);

export const invalidSharedLink = createAction(
  `${actionDesc} Invalid Shared Link`,
  props<{ slug: string, reason?: string }>()
);
