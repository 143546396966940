import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessNameComponent } from './components/business-name/business-name.component';
import { BusinessLogoComponent } from './components/business-logo/business-logo.component';
import { IonicModule } from '@ionic/angular';


@NgModule({
  declarations: [
    BusinessNameComponent,
    BusinessLogoComponent
  ],
  exports: [
    BusinessLogoComponent,
    BusinessNameComponent
  ],
  imports: [
    CommonModule,
    IonicModule
  ]
})
export class BusinessAvatarModule {
}
