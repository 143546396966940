import { InjectionToken } from '@angular/core';
import { AuthServiceInterface } from '@pages/auth/services/auth-service-interface';
import { Capacitor } from '@capacitor/core';
import { SplashScreenNativeService } from '@core/services/splash-screen/splash-screen-native.service';
import { SplashScreenWebService } from '@core/services/splash-screen/splash-screen-web.service';

export const SPLASH_SCREEN_SERVICE = new InjectionToken<AuthServiceInterface>('SplashScreen');

export const SplashScreenServiceFactory = () => {
  return Capacitor.isNative ?
    new SplashScreenNativeService() :
    new SplashScreenWebService();
};
