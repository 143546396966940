import { Weekday as GraphWeekday } from '../graphql/types';
import moment, { Moment } from 'moment-timezone';
import { Weekday } from '@modules/weekdays-filter/components/weekdays-filter/weekday';
import { AnticipationEpochDates } from '@modules/week-availability/models/anticipation-dates';

export const SYSTEM_START_EPOCH_DATE = 1596240000; // 1 August 2020 00:00:00 GMT-3

export const IONIC_DATE_FORMAT = 'YYYY-MM-DD'; // momentjs format
export const IONIC_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm'; // momentjs format

export const BuenosAiresTimeZone = 'America/Argentina/Buenos_Aires';

export const weekDays = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'] as const;
export const shortDays = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'] as const;

export const MinutesInADay = 24 * 60;

export const monthsNames = [
  'Enero', 'Febrero', 'Marzo', 'Abril',
  'Mayo', 'Junio', 'Julio', 'Agosto',
  'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
] as const;

export const shortMonthsNames = [
  'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'
] as const;

/**
 * Get the month name based on the zero indexed month
 * of the Date class.
 */
export const getMonthNameByIndex = (monthIndex: number): string => {
  return monthsNames[monthIndex] || '';
};

export type WeekDaysKey = keyof typeof GraphWeekday;

export const GraphWeekdayToWeekday = {
  [GraphWeekday.SUNDAY]: weekDays[0],
  [GraphWeekday.MONDAY]: weekDays[1],
  [GraphWeekday.TUESDAY]: weekDays[2],
  [GraphWeekday.WEDNESDAY]: weekDays[3],
  [GraphWeekday.THURSDAY]: weekDays[4],
  [GraphWeekday.FRIDAY]: weekDays[5],
  [GraphWeekday.SATURDAY]: weekDays[6]
} as const;

export const ShortDayToGraphWeekday = {
  Dom: GraphWeekday.SUNDAY,
  Lun: GraphWeekday.MONDAY,
  Mar: GraphWeekday.TUESDAY,
  'Mié': GraphWeekday.WEDNESDAY,
  Jue: GraphWeekday.THURSDAY,
  Vie: GraphWeekday.FRIDAY,
  'Sáb': GraphWeekday.SATURDAY
} as const;

export const GraphWeekdayToShortDay = {
  [GraphWeekday.SUNDAY]: shortDays[0],
  [GraphWeekday.MONDAY]: shortDays[1],
  [GraphWeekday.TUESDAY]: shortDays[2],
  [GraphWeekday.WEDNESDAY]: shortDays[3],
  [GraphWeekday.THURSDAY]: shortDays[4],
  [GraphWeekday.FRIDAY]: shortDays[5],
  [GraphWeekday.SATURDAY]: shortDays[6]
} as const;

export type WeekDayNumber = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const NO_WEEK_DAY_INDEX = -1;
export type WeekDayNumberNotFound = typeof NO_WEEK_DAY_INDEX;

const ONE_MINUTE_IN_SECONDS = 60;
export const SECONDS_IN_ONE_DAY = 86400;

// https://thecodebarbarian.com/a-practical-guide-to-moment-timezone.html
// moment.tz(strDate, timezone), parses a date in a specific timezone.

export const areDatesOnTheSameWeek = (epoch1: Epoch, epoch2: Epoch, timezone: string): boolean => {
  return toMomentDate(epoch1, timezone).week() === toMomentDate(epoch2, timezone).week();
};

export const toMomentDate = (epoch: number, timezone: string): Moment => {
  return moment.unix(epoch).tz(timezone);
};

export const toMomentDateFromDate = (date: Date, timezone: string): Moment => {
  return moment(date).tz(timezone);
};

export const parseISOStringDate = (isoDate: string, timezone: string): Moment => {
  return moment.tz(isoDate, IONIC_DATETIME_FORMAT, timezone);
};

/***
 * @deprecated Don't use it anymore
 * @param epoch
 * @param timezone
 */
export const toDate = (epoch: number, timezone: string): Date => {
  return toMomentDate(epoch, timezone).toDate();
};

export const getDateNumberFromEpoch = (epoch: number, timezone: string): number => {
  return toMomentDate(epoch, timezone).get('date');
};

export const getDateNumberFromDate = (date: Date, timezone: string): number => {
  return toMomentDateFromDate(date, timezone).get('date');
};

export const getDayNumberFromDate = (date: Date, timezone: string): number => {
  return toMomentDateFromDate(date, timezone).get('day');
};

export const currentEpoch = (timezone?: string): number => {
  if (timezone) {
    return moment.tz(timezone).unix();
  }
  return moment().unix();
};

// @ts-ignore
window['currentEpoch'] = currentEpoch;

export const getCurrentDate = (timezone: string): Moment => {
  return moment.tz(timezone);
};

export const getEpoch = (date: Date): Epoch => {
  return moment(date).unix();
};
/**
 * Returns the start of the date in a given timezone.
 * For example, for 1591798579 (Wednesday, June 10, 2020 11:16:19 AM GMT-03:00)
 * it returns 1591758000  (Wednesday, June 10, 2020 12:00:00 AM GMT-03:00)
 */
export const toStartOfDay = (epoch: Epoch, timezone: string): Epoch => {
  return moment.unix(epoch).tz(timezone).startOf('day').unix();
};

export const toEndOfDay = (epoch: Epoch, timezone: string): Epoch => {
  return moment.unix(epoch).tz(timezone).add(1, 'day').startOf('day').unix();
};

/**
 * Get the begging of a week for the given date on a specific timezone
 */
export const toStartOfWeek = (epoch: Epoch, timezone: string): Epoch => {
  // different timezones could have a different start of week
  // (that's why I don't user startOf('week'))
  return moment.unix(epoch).tz(timezone).day(0).startOf('day').unix();
};

/**
 * Get the begging of a week for the given date on a specific timezone
 */
export const toEndOfWeek = (epoch: Epoch, timezone: string): Epoch => {
  // different timezones could have a different start of week
  // (that's why I don't user startOf('week'))
  return moment.unix(epoch).tz(timezone).day(6).startOf('day').unix();
};

export const toStartOfMonth = (epoch: Epoch, timezone: string): Epoch => {
  // different timezones could have a different start of week
  // (that's why I don't user startOf('week'))
  return moment.unix(epoch).tz(timezone).startOf('month').unix();
};

export const toEndOfMonth = (epoch: Epoch, timezone: string): Epoch => {
  // different timezones could have a different start of week
  // (that's why I don't user startOf('week'))
  return moment.unix(epoch).tz(timezone).endOf('month').unix();
};


/**
 * Get the Wednesday day of the current week for given date
 */
export const getWednesdayOfTheWeek = (epoch: Epoch, timezone: string): Epoch => {
  const startOfWeek = toStartOfDay(epoch, timezone);
  return moment.unix(startOfWeek).add(3, 'days').unix();
};

export const addDays = (epoch: Epoch, days: number, timezone: string): Epoch => {
  return toMomentDate(epoch, timezone).add(days, 'days').unix();
};

export const addMonths = (epoch: Epoch, months: number, timezone: string): Epoch => {
  return toMomentDate(epoch, timezone).add(months, 'months').unix();
};

/**
 * The difference is calculated adding 7 days, so we need to pass
 * what is the first day of the week and the last day of the week.
 * @param fromEpoch Begging of the week
 * @param toEpoch Last day of the week
 * @param timezone Business timezone
 */
export const differenceInWeeks = (fromEpoch: Epoch, toEpoch: Epoch, timezone: string): number => {
  const moment1 = toMomentDate(fromEpoch, timezone);
  const moment2 = toMomentDate(toEpoch, timezone);
  return moment2.diff(moment1, 'weeks');
};

export const isBetween = (date: Epoch, dateFrom: Epoch, dateTo: Epoch): boolean => {
  return dateFrom <= date && date <= dateTo;
};

export const addMinutes = (epoch: Epoch, minutes: number): Epoch => {
  return epoch + (minutes * ONE_MINUTE_IN_SECONDS);
};

export const sortWeekDays = (weekdays: Weekday[]): Weekday[] => {
  return [...weekdays].sort((wd1, wd2) => {
    const wd1Post = shortDays.indexOf(wd1);
    const wd2Post = shortDays.indexOf(wd2);
    return wd1Post - wd2Post;
  });
};

export const getAnticipationDates = (minHours: number, maxDays: number, timezone: string): AnticipationEpochDates => {
  const now = currentEpoch(timezone);
  return {
    min: now + (minHours * 60 * 60),
    max: addDays(now, maxDays, timezone)
  };
};
