import { Injectable } from '@angular/core';
import { BaseAppSyncClient } from '@core/services/app-sync/base-app-sync-client';
import { SessionService } from '@core/session/services/session.service';
import { Apollo } from 'apollo-angular';
import { AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link/lib/auth-link';

@Injectable({
  providedIn: 'root'
})
export class AppSyncClient extends BaseAppSyncClient {

  constructor(private sessionService: SessionService,
              apollo: Apollo) {
    super(apollo);
  }

  getAuthOption(): AuthOptions {
    return {
      type: AUTH_TYPE.OPENID_CONNECT,
      jwtToken: () => this.sessionService.getToken(),
    };
  }

  protected shouldReport401Error(): boolean {
    return false;
  }

  protected getApolloClientName(): string {
    return 'privateClient';
  }

}
