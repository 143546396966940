import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuHeaderComponent } from './menu-header/menu-header.component';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { MenuButtonComponent } from './menu-button/menu-button.component';
import { AvatarModule } from '../avatar/avatar.module';
import { AccountsModule } from '../accounts/accounts.module';
import { UserProfileTypeComponent } from '@modules/user-profile-type/user-profile-type.component';


@NgModule({
  declarations: [MenuHeaderComponent, MenuButtonComponent],
  exports: [
    MenuHeaderComponent,
    MenuButtonComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    AvatarModule,
    AccountsModule,
    UserProfileTypeComponent,
  ]
})
export class SideMenuModule {
}
