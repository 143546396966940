import { Injectable, NgZone } from '@angular/core';
import { PluginListenerHandle, Plugins } from '@capacitor/core';
import { HandleSharedLinkService } from '@pages/share/services/handle-shared-link-service';
import { LazyLoadService } from '@core/services/lazy-load.service';
import { environment } from '@env/environment';
import { AppUrlOpen } from '@capacitor/core/dist/esm/core-plugin-definitions';
import { UserViewAppointmentsFlow } from '@pages/user-view/pages/appointment-flow/appointment-flow-routes.names';
import { SelectBusiness } from '@pages/user-view/pages/appointment-flow/pages/search-business/select-business-routes.names';

const {App} = Plugins;

@Injectable({
  providedIn: 'root'
})
export class DeepLinkingService {

  appUrlOpenHandler: PluginListenerHandle;

  constructor(private zone: NgZone,
              private lazyLoadService: LazyLoadService,
              private handleSharedLinkService: HandleSharedLinkService) {
  }

  async listenForLinks() {
    console.log('DeepLinkingService: staring...');
    if (this.appUrlOpenHandler) {
      console.log('appUrlOpenHandler already started!!');
      return;
    }
    console.log('Loading share Module');
    // Load Share Module to handle the deep links
    await this.loadShareModule();
    console.log('Share Module loaded');

    // Fires events after start listening, even if the listener is registered
    // later after the app started
    this.appUrlOpenHandler = App.addListener('appUrlOpen', (data: AppUrlOpen) => {
      // {"url":"https://appointia-20815.firebaseapp.com/share/make-appointment/123"}
      console.log('appUrlOpen:data: ', JSON.stringify(data));
      this.zone.run(() => {
        this.handleAppUrlOpen(data.url);
      });
    });
  }

  private handleAppUrlOpen(url: string) {
    const slug = this.getSlug(url);
    console.log('Dispatching deep linking slug: ', slug);
    this.handleSharedLinkService.handleSharedLink(slug);
  }

  /**
   * Returns everything after the ".com" or whatever the domain was.
   * For example:
   * * https://appointia-20815.firebaseapp.com => ""
   * * https://appointia-20815.firebaseapp.com/ => "/"
   * @param url the app launch url
   */
  getSlug(url: string): string {
    const limit = 2;
    const appUrl = environment.web.appUrl;
    // environment.web.appUrl
    const urlParts = (url || '').split(appUrl, limit);
    // Part 1: "https://appointia-20815.firebaseapp.com"
    // Part 2: "/share/make-appointment/123" (slug)
    if (urlParts.length === limit) {
      // slug
      return urlParts[1];
    }
    return '';
  }

  async wasAppInitializedFromDeepLink(): Promise<boolean> {
    // Full url exactly like the clicked by the user (example: "https://appointia-20815.firebaseapp.com/")
    // Returns undefined if the app was not initiated by a link (it was open clicking the app icon)
    const {url} = await App.getLaunchUrl();
    console.log('App Launch Url: ', url);
    console.log('App lunch location.pathname: ', location.pathname);
    return url?.length > 0 ||
      HandleSharedLinkService.isValidShareSlug(location.pathname) ||
      location.pathname.startsWith(`${UserViewAppointmentsFlow}/${SelectBusiness}`
      );
  }

  async loadShareModule(): Promise<void> {
    const sharedModule = await import('../../pages/share/share.module').then(m => m.ShareModule);
    await this.lazyLoadService.loadModule(sharedModule);
  }

}
