export const environment = {
  production: true,
  appName: 'Appointia',
  firebase: {
    config: {
      apiKey: 'AIzaSyAP9LoUk8y25Jt7rMHKPb2iKHVIz0WLLfI',
      authDomain: 'app.appointia.com.ar',
      databaseURL: 'https://appointia-20815.firebaseio.com',
      projectId: 'appointia-20815',
      storageBucket: 'appointia-20815.appspot.com',
      messagingSenderId: '807914094311',
      appId: '1:807914094311:web:d22d71f4b7b04a64de2c42',
      measurementId: 'G-VB3Y4017WB'
    }
  },
  awsConfig: {
    aws_appsync_graphqlEndpoint: 'https://2myxakk5j5bghh3zc7u74lysoe.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'OPENID_CONNECT',
    aws_appsync_apiKey: null,
    identity_pool_id: 'us-east-1:0f52eb3e-d53d-4f37-81a6-d95f287466a0',
  },
  algolia: {
    app_id: '2GIV270RHU',
    api_key: 'b2952aece74e85d8b18e71a338be68a8',
    businessesIndex: 'dev_business',
    servicesIndex: 'services'
  },
  mapbox: {
    access_token: 'pk.eyJ1IjoiYXBwb2ludGlhIiwiYSI6ImNrOG5vdGp5MjB2bDkzbW80dXZrMmQ2eWkifQ.VtmxHeAg_CAk_vrhpOetZA'
  },
  logrocket: {
    key: 'appointia/appointia-client'
  },
  sentry: {
    organization: 'appointia',
    projectId: 5200118,
    dsn: 'https://dbe661a2a9034aa6b2f25293a7181083@o377667.ingest.sentry.io/5200118'
  },
  posthog: {
    apiKey: 'phc_8RbkaI2Yl4MAXmw73ZTm2DInoCpwCZd63Ey9s2ooH6T',
    apiHost: 'https://app.posthog.com'
  },
  web: {
    domain: 'appointia.com.ar',
    appUrl: 'https://appointia.com.ar'
  },
};
