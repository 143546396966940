<ion-item lines="none" class="account" [button]="true" [detail]="false">
    <ion-avatar slot="start" class="account__logo">
        <appo-account-logo
                class="account__logo"
                [account]="account">
        </appo-account-logo>
    </ion-avatar>
    <ion-label>
        <appo-display-name [resource]="account"></appo-display-name>
        <appo-user-profile-type
                *ngIf="isBusinessAccount(account.accountType)"
                [isAdmin]="isAdmin">
        </appo-user-profile-type>
    </ion-label>
    <ng-content select="[slot-end]"></ng-content>
</ion-item>
