<div class="resource-avatar"
     [class.resource-avatar--no-padding]="pictureUrl"
     [ngClass]="(size==='small')?'resource-avatar--small':'resource-avatar--big'">
    <div *ngIf="!pictureUrl" class="resource-avatar__char">{{initialChar}}</div>
    <img
            *ngIf="pictureUrl"
            [src]="pictureUrl"
            class="resource-avatar__img"
            (error)="onError()"
            referrerpolicy="no-referrer"
    />
</div>
