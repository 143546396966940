import moment from 'moment-timezone';
import { BuenosAiresTimeZone } from '@utils/date.utils';

const isArgentinaTimezone = (timezone: string): boolean => new RegExp(/America\/Argentina/ig).test(timezone);

// 'America/Buenos_Aires' is deprecated
const isDeprecatedBuenosAires = (timezone: string): boolean => new RegExp(/America\/Buenos_Aires/ig).test(timezone);

const isBuenosAiresTimezone = (timezone: string): boolean => new RegExp(/America\/Argentina\/Buenos_Aires/ig).test(timezone);

const continents = ['America', 'Africa', 'Asia', 'Europe'];

let defaultTimezone = '';
export const getDefaultTimezone = () => {
  if (defaultTimezone) {
    return defaultTimezone;
  }
  defaultTimezone = moment.tz.guess();
  if (isArgentinaTimezone(defaultTimezone) || isDeprecatedBuenosAires(defaultTimezone)) {
    defaultTimezone = BuenosAiresTimeZone;
  }
  return defaultTimezone;
};

const filterExcludedTimezones = (timezones: string[]): string[] => {
  return timezones.filter(timezone => {
    const isArgentina = isArgentinaTimezone(timezone);
    if (isArgentina) {
      return isBuenosAiresTimezone(timezone);
    }

    // 'America/Buenos_Aires' is deprecated
    if (isDeprecatedBuenosAires(timezone)) {
      return false;
    }
    return true;
  });
};

export const ALL_TIMEZONES: Readonly<string[]> = filterExcludedTimezones(moment.tz.names());

export const formatTimezone = (timezone: string): string => timezone
  .split('/')
  .map(word => word.replace('_', ' '))
  .join(', ');


export const getReadableTimezone = (timezone: string): string => {
  return timezone
    .split('/')
    .filter(tzPart => !continents.includes(tzPart))
    .map(word => word.replace('_', ' '))
    .join(', ');
};
