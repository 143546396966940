import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { AccountsService } from '@modules/accounts/services/accounts.service';
import { SessionService } from '@core/session/services/session.service';

const MaxBusinessAccounts = 5;

@Injectable({
  providedIn: 'root'
})
export class RegisterBusinessService {

  constructor(private accountsService: AccountsService,
              private sessionService: SessionService) {
  }

  canCreateBusinessAccount(): Observable<boolean> {
    return this.accountsService.getBusinessAccounts().pipe(
      filter(() => this.sessionService.validSession$.value),
      switchMap((businessAccounts) => from(this.sessionService.hasRoleRoot()).pipe(
        map((userHasRoleRoot) => {
          console.log('************************** CreateOneBusinessGuard:userHasRoleRoot: ', userHasRoleRoot);
          console.log('************************** CreateOneBusinessGuard:businessAccounts: ', businessAccounts.length);
          if (userHasRoleRoot) {
            return true;
          }
          // Users can only create 5 business account
          return businessAccounts.length <= MaxBusinessAccounts;
        }),
      )),
    );
  }
}
