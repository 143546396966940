<ng-container *ngIf="accounts$ | async as accounts">
    <div *ngIf="accounts.length">
        <div class="title">Cambiar de cuenta</div>
        <appo-account
                *ngFor="let account of accounts$ | async"
                [account]="account"
                [isAdmin]="userPermissionsService.isUserAdmin(account.accountId) | async"
                (click)="setActiveAccount(account)">
        </appo-account>
    </div>
</ng-container>
