export const Share = 's';

export const ShareBusinessIdParam = 'businessId';


// Share Business Make Appointment Handle
export const MakeAppointmentBusinessHandleParam = 'businessHandle';
export const MakeAppointment = `b/:${MakeAppointmentBusinessHandleParam}`;

// Share Appointment
export const ShareAppointmentId = 'appointmentId';
export const ShareAppointment = `a/:${ShareAppointmentId}`;

// Export Business Appointments
export const ExportId = 'exportId';
export const ExportAppointments = `e/:${ShareBusinessIdParam}/:${ExportId}`;


// **** FULL SHARE URLS ****

// Share Business Make Appointment
export const ShareMakeAppointmentUrl = `/${Share}/${MakeAppointment}`;

// Share Appointment
export const ShareAppointmentUrl = `/${Share}/${ShareAppointment}`;


export const ShareBusinessExportAppointmentsUrl = `/${Share}/${ExportAppointments}`;
