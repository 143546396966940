import { RemoteConfigKeys } from '@models/remote-config-keys';
import { RemoteConfigDefaultValues } from '@core/services/firebase.service';


export const DISABLED_MIN_VERSION = '-1';

export interface RemoteConfigAppMinimumVersion {
  web: string;
  android: string;
  ios: string;
}

export const getDefaultConfig = (): RemoteConfigDefaultValues => {
  return {
    [RemoteConfigKeys.DemoConfig]: '{"demoUsers":[]}',
    [RemoteConfigKeys.AppMinimumVersion]: `{"web":"${DISABLED_MIN_VERSION}", "android":"${DISABLED_MIN_VERSION}", "ios":"${DISABLED_MIN_VERSION}" }`
  };
};

export const getDefaultConfigSettings = () => {
  return {
    minimumFetchIntervalMillis: 30 * 60 * 1000, // 30 min
    fetchTimeoutMillis: 10000 // fetch timeout
  };
};
