import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadActiveAccount, loadActiveAccountFinished, setActiveAccount, updateActiveAccount } from '../actions/accounts.actions';
import { catchError, concatMap, filter, first, map, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, from, of } from 'rxjs';
import { AccountsService } from '../services/accounts.service';
import { Account } from '../models/account';
import { UserService } from '@modules/user/services/user.service';
import { businessConfigurationChanged } from '@pages/admin-view/pages/business-configuration/actions/business-configuration.actions';
import { loadUserSuccess } from '@modules/user/actions/user.actions';

@Injectable({
  providedIn: 'root'
})
export class AccountsEffects {

  constructor(private actions$: Actions,
              private userService: UserService,
              private accountsService: AccountsService) {
  }

  loadActiveAccount$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadActiveAccount),
      switchMap(() => this.accountsService.loadOrCreateAccount().pipe(
        switchMap((account: Account) => {
          console.log('getSavedAccount: ', account);
          if (account) {
            this.accountsService.setActiveAccount(account);
            // Wait active Account to be set
            return this.accountsService.waitForValidAccount();
          }
          return of(account);
        }),
        map((account) => loadActiveAccountFinished({account})),
        catchError((e) => {
          console.error(e);
          return of(loadActiveAccountFinished({account: undefined}));
        })
      ))
    )
  );

  setActiveAccount$ = createEffect(
    () => this.actions$.pipe(
      ofType(setActiveAccount),
      concatMap(action => from(this.accountsService.saveAccount(action.account)).pipe(
        map(() => action.account)
      )),
      tap(account => {
        if (account.isFromRoot) {
          this.userService.addAdminBusinessAccount(this.accountsService.mapAccountToUserBusinessV2Business(account));
        }
      })
    ), {dispatch: false}
  );

  /**
   * Refreshed the active business Account for the left side menu:
   * 1 - Get all the accounts (refreshUser)
   * 2 - Find the active account from the refreshed account using activeAccount
   * 3 - Set the active account with the new data
   */
  onBusinessConfigurationChanged$ = createEffect(
    () => this.actions$.pipe(
      ofType(businessConfigurationChanged),
      concatMap(() => this.userService.refreshUser()),
      switchMap(() => combineLatest([
        this.accountsService.getBusinessAccounts(),
        this.accountsService.getActiveAccount(),
      ]).pipe(
        first(),
        map(([refreshedBusinessAccounts, activeAccount]) =>
          refreshedBusinessAccounts.find(account => account.accountId === activeAccount.accountId)
        ),
        filter(businessAccount => !!businessAccount),
        tap(businessAccount => {
          // When setting an account, refreshTabs will be called
          this.accountsService.setActiveAccount(businessAccount);
        })
      ))
    ), {dispatch: false}
  );

  /**
   * Update the persisted active account in localStorage
   */
  onLoadUserSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadUserSuccess),
      map(action => action.user),
      switchMap((user) => this.accountsService.getActiveAccount().pipe(
        take(1),
        map(activeAccount => {
          return user.businessesV2.find(businessV2 => businessV2.business.businessId === activeAccount.accountId);
        }),
        filter(business => !!business),
        map(businessV2 => this.accountsService.mapBusinessToAccount(businessV2.business)),
        tap(account =>       this.accountsService.saveAccount(account)        ),
        map(account => updateActiveAccount({account}))
      ))
    )
  );

}

