import { gql } from 'apollo-angular';

export const FavouriteBusinessFragment = gql`
  fragment FavouriteBusiness on Business {
    businessId
    businessHandle
    name
    pictureUrl
    isDemo
    enabled
  }
`;

