import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '@env/environment';
import { routerFeatureKey, routerReducer, RouterStateUrl } from './router';
import { RouterReducerState } from '@ngrx/router-store';
import { getAppMetaReducers } from './meta-reducers';

export interface State {
  [routerFeatureKey]: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<State> = {
  [routerFeatureKey]: routerReducer
};

const appMetaReducers = getAppMetaReducers();

export const metaReducers: MetaReducer<State>[] = !environment.production ? [...appMetaReducers] : [...appMetaReducers];
