import { inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SentryErrorHandlerService } from '@modules/sentry/sentry-error-handler.service';
import { GraphQLErrorType } from '@graphql/graphql-error-types';
import { Apollo, ApolloBase } from 'apollo-angular';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { ApolloLink, HttpLink, InMemoryCache } from '@apollo/client/core';
import { environment } from '@env/environment';
import { createAuthLink } from 'aws-appsync-auth-link';
import { AuthOptions } from 'aws-appsync-auth-link/lib/auth-link';
import { RetryLink } from '@apollo/client/link/retry';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseAppSyncClient {

  protected constructor(private apollo: Apollo) {
    this.sentryErrorHandlerService = inject(SentryErrorHandlerService);
    this.init();
  }

  protected apolloClient: ApolloBase;

  protected sentryErrorHandlerService: SentryErrorHandlerService;

  static getPresentErrorType(
    errorResponse: any | undefined,
    graphQLErrorTypes: GraphQLErrorType[]
  ): GraphQLErrorType | undefined {

    // Get all GraphQLErrorType present in the response error
    const graphqlErrorTypesInResponse: GraphQLErrorType[] = errorResponse.graphQLErrors
      ?.map(e => {
        try {
          const message = JSON.parse(e.message);
          if (graphQLErrorTypes.includes(message.name)) {
            return message.name as GraphQLErrorType;
          }
        } catch (e) {
          console.error(e);
          return undefined;
        }
      })
      .filter(e => e != null);

    return graphQLErrorTypes.find(graphqlErrorType => graphqlErrorTypesInResponse?.includes(graphqlErrorType));
  }

  protected abstract getApolloClientName(): string;

  protected abstract getAuthOption(): AuthOptions;

  protected abstract shouldReport401Error(): boolean;


  init() {
    this.apollo.createNamed(this.getApolloClientName(), this.getApolloOptions());
    this.apolloClient = this.apollo.use(this.getApolloClientName());
  }

  query<T>(query: any, variables: any = {}): Observable<T> {
    return from(this.apolloClient.query<T>({
      query,
      variables,
      // https://www.apollographql.com/docs/react/api/react-apollo/#graphql-config-options-fetchPolicy
      fetchPolicy: 'no-cache', // 'network-only',
    })).pipe(
      map(response => response.data),
    );
  }

  mutation<T>(mutation: any, variables: any = {}): Observable<T> {
    return from(this.apolloClient.mutate<T>({
      mutation,
      variables,
    })).pipe(
      map(response => response.data)
    );
  }

  // TODO call this on logout & on Init
  async deleteClient() {
    this.apollo.removeClient(this.getApolloClientName());
    this.apolloClient = null;
  }

  isAuthError(errorResponse: ErrorResponse): boolean {
    if (!errorResponse) {
      return false;
    }
    // @ts-ignore
    const errorStatusCode = errorResponse.networkError?.statusCode;
    return errorStatusCode === 401;
  }

  private getApolloOptions() {
    const errorLink = onError((e: ErrorResponse) => {
      if (e.graphQLErrors) {
        e.graphQLErrors.map(({message, locations, path}) =>
          console.log(
            `[GraphQL ${this.getApolloClientName()} error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      }

      if (!this.shouldReport401Error() && this.isAuthError(e)) {
        console.log(`[${this.getApolloClientName()}] Graphql Apollo 401 network error code`);
        return;
      }

      const msg = {
        operation: e.operation.operationName,
        variables: e.operation.variables,
        appSyncClientName: this.getApolloClientName(),
      };
      this.sentryErrorHandlerService.handleError(e, JSON.stringify(msg));
    });

    const httpLink = new HttpLink({
      uri: environment.awsConfig.aws_appsync_graphqlEndpoint
    });

    // Only retries on network errors, not graphql errors
    const retryLink = new RetryLink({
      delay: {
        initial: 1000,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 20,
        retryIf: (error, operation) => {
          console.error('[RetryLink] error: ', error);
          console.error('[RetryLink] operation: ', operation);
          return !!error;
        }
      }
    });

    // https://github.com/awslabs/aws-mobile-appsync-sdk-js/pull/561#issuecomment-701696316
    const authLink = createAuthLink({
      url: environment.awsConfig.aws_appsync_graphqlEndpoint,
      region: environment.awsConfig.aws_appsync_region,
      auth: this.getAuthOption()
    });

    return {
      cache: new InMemoryCache(),
      // Order in the link array MATTERS!!!
      link: ApolloLink.from([
        errorLink,
        retryLink,
        authLink,
        httpLink
      ])
    };
  }

}
