<div *ngIf="activeAccount$ | async as activeAccount" class="menu-header">
    <appo-account-logo class="menu-header__profile_pic"
                       [account]="activeAccount">
    </appo-account-logo>
    <div class="menu-header__name">
        <appo-display-name [resource]="activeAccount"></appo-display-name>
        <ng-container *ngIf="isBusinessAccount(activeAccount.accountType)">
            <appo-user-profile-type
                    [isAdmin]="userPermissionsService.isUserAdmin(activeAccount.accountId) | async">
            </appo-user-profile-type>
        </ng-container>
    </div>
</div>
