import { createAction, props } from '@ngrx/store';
import { Account } from '../models/account';

const actionDesc = '[Account]';

export type SetActiveAccountOrigin = 'switch-account-from-menu' | 'other';

export const setActiveAccount = createAction(
  `${actionDesc} - Set Active Account`,
  props<{ account: Account, origin: SetActiveAccountOrigin }>()
);

export const loadActiveAccount = createAction(`${actionDesc} - Load Active Account`);
export const loadActiveAccountFinished = createAction(
  `${actionDesc} - Load Active Account Finished`,
  props<{ account: Account | undefined }>()
);

export const updateActiveAccount = createAction(
  `${actionDesc} - Update Active Account`,
  props<{ account: Account }>()
);
