import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import jwt_decode from 'jwt-decode';
import { FirebaseService } from '../../services/firebase.service';
import { StorageService } from '../../services/storage.service';
import { FirebaseUser } from '@models/user';
import { Router } from '@angular/router';
import { AuthLogout } from '@pages/auth/auth-routes.names';
import { DecodedToken } from '@/types';

const TOKEN = 'token';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  validSession$ = new BehaviorSubject<boolean>(false);

  constructor(private storage: StorageService,
              private router: Router) {
  }

  async init() {
    const validSession = await this.hasValidSession();
    this.validSession$.next(validSession);

    FirebaseService.getApp().auth().onIdTokenChanged(async (user: FirebaseUser) => {
      if (user) {
        await this.saveToken(await this.getToken());
        this.validSession$.next(true);
      } else {
        await this.storage.removeKey(TOKEN);
        this.validSession$.next(false);
      }
    });
    this.listenForInvalidSessions();
  }

  /**
   * If Firebase account is disabled or not logged in,
   * but there's a token, trigger logout
   * because this is an invalid session.
   */
  private listenForInvalidSessions() {
    FirebaseService.getApp().auth().onAuthStateChanged((user) => {
      if (!user) {
        setTimeout(async () => {
          const hasValidSession = await this.hasValidSession();
          console.log('----- SessionService.onAuthStateChanged:hasValidSession: ', hasValidSession);
          if (hasValidSession) {
            this.invalidateSession();
          }
        });
      }
    });
  }

  invalidateSession() {
    console.log('----- SessionService.invalidateSession');
    this.router.navigateByUrl(AuthLogout);
  }

  hasValidSession$(): Observable<boolean> {
    return this.validSession$.asObservable();
  }

  async hasValidSession() {
    const token = await this.getSavedToken();
    return !!token && token.length > 0;
  }

  async resetSession() {
    this.validSession$.next(false);
    await this.storage.clear();
  }

  async getToken(forceRefresh = false): Promise<string> {
    const user: FirebaseUser = await FirebaseService.getCurrentUser();
    if (!user) {
      return '';
    }
    return user.getIdToken(forceRefresh);
  }

  async refreshToken() {
    await this.getToken(true);
  }

  private saveToken(token: string): Promise<void> {
    return this.storage.set(TOKEN, token);
  }

  private getSavedToken(): Promise<string> {
    return this.storage.get(TOKEN);
  }

  async getDecodedToken(): Promise<DecodedToken | undefined> {
    try {
      const token = await this.getToken();
      if (!token) {
        return undefined;
      }
      return jwt_decode(token);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }

  async getRoleFromJwt(): Promise<string> {
    const tokenPayload = await this.getDecodedToken();
    return tokenPayload?.role || '';
  }

  async hasRoleDemo(): Promise<boolean> {
    const role = await this.getRoleFromJwt();
    return role === 'demo';
  }

  async hasRoleRoot(): Promise<boolean> {
    const role = await this.getRoleFromJwt();
    return role === 'root';
  }

}
