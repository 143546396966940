import { Injectable } from '@angular/core';
import { UserService } from '@modules/user/services/user.service';
import { Observable } from 'rxjs';
import { filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AccountsService } from '@modules/accounts/services/accounts.service';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionsService {

  isUserAdminForActiveOnActiveBusiness$: Observable<boolean>;

  constructor(private userService: UserService,
              private accountsService: AccountsService) {

    this.isUserAdminForActiveOnActiveBusiness$ = this.isUserAdminForActiveBusiness().pipe(
      tap((isUserAdminForActiveBusiness) => {
        console.log('Running isUserAdminForActiveOnActiveBusiness$: ', isUserAdminForActiveBusiness);
      }),
      shareReplay(1)
    );
  }

  isUserAdmin(businessId: string): Observable<boolean> {
    return this.userService.getUser().pipe(
      filter(user => !!user),
      map(user => {
        const businessInfo = user.businessesV2.find(businessV2 => businessV2.business.businessId === businessId);
        return businessInfo?.readOnly === false;
      })
    );
  }

  private isUserAdminForActiveBusiness(): Observable<boolean> {
    return this.accountsService.getActiveAccount().pipe(
      filter(account => !!account),
      switchMap(account => this.isUserAdmin(account.accountId))
    );
  }
}
