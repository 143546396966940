import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import * as bowser from 'bowser';
import { Platform } from '@ionic/angular';

const {App, Browser} = Plugins;

@Injectable({
  providedIn: 'root'
})
export class BrowserService {

  constructor(private platform: Platform) {
  }

  openUrl(url: string): Promise<void> {
    return Browser.open({
      url,
      // toolbarColor: '#4A65F5',
    });
  }

  /**
   * Return browserName-MajorVersion
   */
  getBrowserInfo(): string {
    try {
      const browserInfo = bowser.parse(window.navigator.userAgent);
      console.log('browserInfo: ', browserInfo);
      const browserVersion = browserInfo.browser.version;

      let majorVersion = 'n/a';
      if (browserVersion) {
        const firstDotIndex = browserVersion.indexOf('.');
        majorVersion = browserVersion.substring(0, firstDotIndex);
      } else {
        const os = `${browserInfo.os.name}-${browserInfo.os.version}`;
        majorVersion = os;
      }

      return `${browserInfo.browser.name.toLowerCase()}-${majorVersion}`;
    } catch (e) {
      console.error('getBrowserInfo Error: ', e);
      return 'NO_BROWSER_DETECTED';
    }
  }

  async openInExternalBrowser(url: string): Promise<void> {
    if (this.platform.is('capacitor')) {
      await App.openUrl({url});
    }
    window.open(url, '_blank');
  }

}
