import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

export const defaultBusinessPictureUrl = 'assets/icons/common/business.svg';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'appo-business-logo',
  templateUrl: './business-logo.component.html',
  styleUrls: ['./business-logo.component.scss']
})
export class BusinessLogoComponent implements OnChanges {

  @Input()
  pictureUrl: string;

  businessPictureUrl = '';

  errorOccurred = false;

  ngOnChanges(changes: SimpleChanges) {
    this.errorOccurred = false;
    this.businessPictureUrl = this.pictureUrl || defaultBusinessPictureUrl;
  }

  onError() {
    // Prevent infinite loop on errors
    if (this.errorOccurred) {
      return;
    }
    this.errorOccurred = true;
    this.businessPictureUrl = defaultBusinessPictureUrl;
  }

}
