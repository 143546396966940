import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SentryErrorHandlerService } from './sentry-error-handler.service';


@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    {provide: ErrorHandler, useClass: SentryErrorHandlerService}
  ]
})
export class SentryModule {
}
