import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Account } from '../../models/account';
import { AccountType } from '@modules/accounts/models/account-type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'appo-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent {

  @Input({required: true})
  account: Account;

  @Input({required: true})
  isAdmin = true;

  isBusinessAccount(accountType: AccountType): boolean {
    return accountType === AccountType.Business;
  }
}
