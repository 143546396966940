import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  checkCallbackLogin,
  login,
  LoginExtraData,
  loginFailure,
  loginSuccess,
  logout,
  sendRecoverPwdEmail
} from '../actions/auth.actions';
import { SignInProvider } from '../models/sign-in-provider';
import { RecoverPasswordForm } from '../pages/forgot-password/recover-password-form.interface';
import { AuthState } from '../reducers/auth.reducer';
import { UserService } from '@modules/user/services/user.service';
import { AnalyticsService } from '@modules/analytics/analytics.service';
import { SentryErrorHandlerService } from '@modules/sentry/sentry-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private store: Store<AuthState>,
              private userService: UserService,
              private sentryErrorHandlerService: SentryErrorHandlerService,
              private analyticsService: AnalyticsService) {
  }

  login(signInType: SignInProvider, extraData?: LoginExtraData) {
    this.store.dispatch(login({signInType, extraData}));
  }

  logout() {
    this.store.dispatch(logout());
  }

  checkAuthWebCallback() {
    this.store.dispatch(checkCallbackLogin());
  }

  async handleLoginSuccess() {
    console.log('handleLoginSuccess:user');
    const signInProvider = await this.userService.getUserSignedInProvider();
    this.analyticsService.logEvent('LOGIN_SUCCESS', {
      signInProvider,
    });
    this.store.dispatch(loginSuccess());
  }

  async handleLoginFailure(error: any) {
    this.sentryErrorHandlerService.handleError(error, 'Error on signIn/Login');
    this.analyticsService.logEvent('LOGIN_FAILED');
    this.store.dispatch(loginFailure());
  }

  sendRecoverPwdEmail(recoverPasswordForm: RecoverPasswordForm) {
    this.store.dispatch(sendRecoverPwdEmail(recoverPasswordForm));
  }

}
