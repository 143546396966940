import { gql } from 'apollo-angular';
import { FullUserFragment } from '@graphql/fragments/full-user';

export default gql`
  query GetMe {
    me {
      ...FullUser
    }
  }
  ${FullUserFragment}
`;
