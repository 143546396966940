import { Action, createReducer, on } from '@ngrx/store';
import { finishHandleSharedLink, handleSharedLink, invalidSharedLink } from '@pages/share/actions/handle-shared-link.actions';

export const sharedLinksFeatureKey = 'shared-links';

export interface SharedLinkState {
  processingSharedLink: boolean,
  slug: string
}

export const initialState: SharedLinkState = {
  processingSharedLink: false,
  slug: ''
};

const sharedLinksReducer = createReducer(
  initialState,

  on(handleSharedLink, (state, action) => ({
    ...state,
    processingSharedLink: true,
    slug: action.slug
  })),

  on(finishHandleSharedLink, invalidSharedLink, state => ({
    processingSharedLink: false,
    slug: ''
  })),
);

export function reducer(state: SharedLinkState | undefined, action: Action) {
  return sharedLinksReducer(state, action);
}
