import { Injectable } from '@angular/core';
import { Account } from '../models/account';
import { Store } from '@ngrx/store';
import { AccountsState } from '../reducer/accounts.reducer';
import { loadActiveAccount, loadActiveAccountFinished, setActiveAccount } from '../actions/accounts.actions';
import { from, Observable, of } from 'rxjs';
import { selectActiveAccount, selectLoadingActiveAccount } from '../selectors/accounts.selectors';
import { UserService } from '../../user/services/user.service';
import { concatMap, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { User } from '@models/user';
import { Business } from '@models/business.model';
import { AccountType } from '../models/account-type';
import { StorageService } from '@core/services/storage.service';
import { Actions, ofType } from '@ngrx/effects';
import { getDefaultTimezone } from '@utils/timezone.utils';
import { UserBusinessV2Business } from '@models/user-business-v2';

const ACTIVE_ACCOUNT_KEY = 'active_account';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private storageService: StorageService,
              private userService: UserService,
              private store: Store<AccountsState>,
              private actions$: Actions) {
  }

  loadActiveAccount() {
    this.store.dispatch(loadActiveAccount());
  }

  setActiveAccount(account: Account) {
    this.store.dispatch(setActiveAccount({account, origin: 'other'}));
  }

  switchToAccount(account: Account) {
    this.store.dispatch(setActiveAccount({account, origin: 'switch-account-from-menu'}));
  }

  saveAccount(account: Account): Promise<void> {
    return this.storageService.set(ACTIVE_ACCOUNT_KEY, JSON.stringify(account));
  }

  async getSavedAccount(): Promise<Account> {
    try {
      return JSON.parse(await this.storageService.get(ACTIVE_ACCOUNT_KEY));
    } catch (e) {
      return null;
    }
  }

  private mapUserToAccount(user: User): Account {
    return {
      accountType: AccountType.User,
      accountId: user.userId,
      pictureUrl: user.pictureUrl,
      name: user.name,
      timezone: getDefaultTimezone(),
      businessHandle: '',
      email: user.email,
      mercadoPagoLinked: false,
    };
  }

  private createUserAccount(): Observable<Account> {
    return this.userService.getUser().pipe(
      take(1),
      map((user: User) => {
        if (!user) {
          return null;
        }
        return this.mapUserToAccount(user);
      }),
      concatMap((account: Account) => from(this.saveAccount(account)).pipe(
        map(() => account),
      ))
    );
  }

  loadOrCreateAccount(): Observable<Account> {
    return from(this.getSavedAccount()).pipe(
      switchMap((account: Account) => {
        if (account) {
          return of(account);
        }
        return this.createUserAccount();
      })
    );
  }

  isLoadingActiveAccount(): Observable<boolean> {
    return this.store.select(selectLoadingActiveAccount);
  }

  getActiveAccount(): Observable<Account> {
    return this.store.select(selectActiveAccount);
  }

  getAccounts(): Observable<Account[]> {
    return this.userService.getUser().pipe(
      map((user: User) => {
        if (!user) {
          return [];
        }
        const userAccount: Account = this.mapUserToAccount(user);
        const businessesAccounts: Account[] = (user.businessesV2 || [])
          .map(businessV2 => businessV2.business)
          .filter(business => business.enabled)
          .map((business: Business) => this.mapBusinessToAccount(business));
        return [userAccount, ...businessesAccounts];
      })
    );
  }

  mapBusinessToAccount(business: UserBusinessV2Business): Account {
    return {
      accountType: AccountType.Business,
      accountId: business.businessId,
      pictureUrl: business.pictureUrl,
      name: business.name,
      timezone: business.timezone,
      businessHandle: business.businessHandle,
      email: business.email,
      isFromRoot: !!business.isFromRoot,
      mercadoPagoLinked: business.mercadoPagoLinked,
    };
  }

  mapAccountToUserBusinessV2Business(account: Account): UserBusinessV2Business {
    return {
      businessId: account.accountId,
      __typename: 'Business',
      resources: [], // Saved accounts don't have resources
      businessHandle: account.businessHandle,
      email: account.email,
      enabled: true,
      name: account.name,
      timezone: account.timezone,
      isFromRoot: account.isFromRoot,
      pictureUrl: account.pictureUrl,
      mercadoPagoLinked: account.mercadoPagoLinked,
    };
  }

  getAccountsToSwitch(): Observable<Account[]> {
    return this.getAccounts().pipe(
      switchMap((accounts: Account[]) => this.getActiveAccount().pipe(
        filter(activeAccount => !!activeAccount),
        map(activeAccount => accounts.filter(account => account.accountId !== activeAccount.accountId))
      ))
    );
  }

  waitForValidAccount(): Observable<Account> {
    return this.getActiveAccount().pipe(
      filter(account => !!account),
      take(1),
    );
  }

  waitFinishedLoading() {
    return this.actions$.pipe(ofType(loadActiveAccountFinished));
  }

  isBusinessAccountActive(): Observable<boolean> {
    return this.getActiveAccount().pipe(
      map(account => account && account.accountType === AccountType.Business)
    );
  }

  isUserAccountActive(): Observable<boolean> {
    return this.getActiveAccount().pipe(
      map(account => account && account.accountType === AccountType.User)
    );
  }

  getUserAccount(): Observable<Account> {
    return this.getAccounts().pipe(
      map(accounts => accounts.find(account => this.isUserAccount(account))),
    );
  }

  switchToUserAccount(): Observable<Account> {
    return this.getUserAccount().pipe(
      filter(userAccount => !!userAccount),
      switchMap((userAccount) => this.switchToAccountById(userAccount.accountId))
    );
  }

  /**
   *
   * @param accountId is the businessId
   */
  switchToAccountById(accountId: string): Observable<Account> {
    return this.getAccounts().pipe(
      map((accounts: Account[]) => accounts.find(account => account.accountId === accountId)),
      tap((account) => {
        if (!account) {
          throw new Error(`Unable to find account with id: ${accountId}`);
        }
        this.setActiveAccount(account);
      }),
      switchMap((account: Account) => this.waitForAccountToBeActive(account)),
    );
  }

  waitForAccountToBeActive(account: Account): Observable<Account> {
    return this.getActiveAccount().pipe(
      filter(activeAccount => activeAccount?.accountId === account.accountId)
    );
  }

  isUserAccount(account: Account): boolean {
    return account.accountType === AccountType.User;
  }

  getBusinessAccounts(): Observable<Account[]> {
    return this.getAccounts().pipe(
      map(accounts => accounts.filter(account => account.accountType === AccountType.Business))
    );
  }

  isCurrentAccountRootAccount(): Observable<boolean> {
    return this.getActiveAccount().pipe(
      map(account => !!account?.isFromRoot)
    );
  }
}
