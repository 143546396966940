import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core'; // Set es-AR locale
import localeEsAr from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MetaReducer, StoreModule, USER_PROVIDED_META_REDUCERS } from '@ngrx/store';
import { metaReducers, reducers } from '@core/reducers';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { AppService } from './app.service';
import { UserModule } from '@modules/user/user.module';

import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from '@core/services/router.serializer.service';
import { extModules } from './build-specifics';
import { SentryModule } from '@modules/sentry/sentry.module';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AccountsModule } from '@modules/accounts/accounts.module';
import { SideMenuModule } from '@modules/side-menu/side-menu.module';
import { Calendar } from '@ionic-native/calendar/ngx';
import { ModalRegisterService } from '@core/services/modal/modal-register.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SPLASH_SCREEN_SERVICE, SplashScreenServiceFactory } from '@core/services/splash-screen/splash-screen-factory';
import { IfUserIsBusinessAdminDirective } from '@modules/if-user-is-business-admin.directive';
import { RemoteConfigEffects } from '@core/effects/remote-config.effects';
import { provideLottieOptions } from 'ngx-lottie';
import { register } from 'swiper/element/bundle';
import { ApolloModule } from 'apollo-angular';


register();

export function getMetaReducers(): MetaReducer<any>[] {
  return metaReducers.concat([]);
}

registerLocaleData(localeEsAr, 'es-Ar');


export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}


@NgModule({
  declarations: [AppComponent],
  imports: [
    ApolloModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonicModule.forRoot({
    // mode: 'ios'
    }),
    AppRoutingModule,
    AccountsModule,
    UserModule,
    SideMenuModule,
    StoreModule.forRoot(reducers, {
        metaReducers,
        runtimeChecks: {
            strictStateImmutability: true,
            strictActionImmutability: true,
            strictStateSerializability: true,
            strictActionSerializability: true,
            strictActionWithinNgZone: true,
            strictActionTypeUniqueness: true,
        }
    }),
    StoreRouterConnectingModule.forRoot({
        serializer: CustomSerializer
    }),
    EffectsModule.forRoot([AppEffects, ModalRegisterService, RemoteConfigEffects]),
    // ServiceWorkerModule.register('ngsw-worker.js', {enabled: !Capacitor?.isNative && environment.production}),
    SentryModule,
    extModules,
    IfUserIsBusinessAdminDirective,
],
  providers: [
    StatusBar,
    Geolocation,
    AppService,
    Calendar,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: LOCALE_ID, useValue: 'es-Ar'},
    {
      provide: USER_PROVIDED_META_REDUCERS,
      useFactory: getMetaReducers,
    },
    {
      provide: SPLASH_SCREEN_SERVICE,
      useFactory: SplashScreenServiceFactory,
      deps: []
    },
    provideLottieOptions({
      player: () => import('lottie-web/build/player/lottie_svg'),
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule {
}
