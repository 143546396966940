import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { SharedLinkState } from '@pages/share/reducers/shared-link.reducer';
import { finishHandleSharedLink, handleSharedLink } from '@pages/share/actions/handle-shared-link.actions';
import { Observable } from 'rxjs';
import { selectIsHandlingSharedLink } from '@pages/share/selectors/handle-shared-link.selectors';
import { Share } from '@pages/share/share-routes.names';

@Injectable({
  providedIn: 'root'
})
export class HandleSharedLinkService {

  constructor(private store: Store<SharedLinkState>) {
  }

  static isValidShareSlug(slug: string): boolean {
    return (slug || '').startsWith(`/${Share}/`);
  }

  handleSharedLink(slug: string) {
    this.store.dispatch(handleSharedLink({slug}));
  }

  finishHandleSharedLink() {
    this.store.dispatch(finishHandleSharedLink());
  }

  isHandlingSharedLink(): Observable<boolean> {
    return this.store.select(selectIsHandlingSharedLink);
  }
}
