import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, delay, filter, map, take, tap } from 'rxjs/operators';
import { loadUser, loadUserFailure, loadUserSuccess, updateUser, UserActionTypes } from '../actions/user.actions';
import { UserService } from '../services/user.service';
import { SentryErrorHandlerService } from '../../sentry/sentry-error-handler.service';
import { Router } from '@angular/router';
import { from, of } from 'rxjs';
import { AuthLogout } from '@pages/auth/auth-routes.names';
import { appResume } from '@app/actions/app.actions';
import { SessionService } from '@core/session/services/session.service';
import { getRandomIDSync } from '@utils/id.utils';
import { UserStatusService } from '@modules/user/services/user-status.service';


@Injectable({
  providedIn: 'root'
})
export class UserEffects {

  constructor(private actions$: Actions,
              private userService: UserService,
              private userStatusService: UserStatusService,
              private sessionService: SessionService,
              private router: Router,
              private sentryErrorHandlerService: SentryErrorHandlerService) {
  }

  onAppResume$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(appResume),
      tap(() => {
        console.log('On appResume with session valid: ', this.sessionService.validSession$.value);
      }),
      filter(() => this.sessionService.validSession$.value),
      map(() => loadUser({
        correlationId: getRandomIDSync(),
      })),
    );
  });

  /**
   * The refresh user will happen in some scenarios:
   *  1) After the user has finish the login process
   *  2) When the App starts and has a valid session
   *  3) Everytime the app comes to foreground
   *  4) After creating a business to get the new business account
   */
  loadUsers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUser),
      concatMap((action) => this.userService.fetchUser().pipe(
          map((user) => {
            let correlationId = '';
            if (action.type === UserActionTypes.LoadUser) {
              correlationId = action.correlationId;
            }
            return loadUserSuccess({user, correlationId});
          }),
          catchError((e) => {
            console.error('loadUser error: ', e);
            return of(loadUserFailure(e));
          }),
        )
      )
    );
  });

  loadUserSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserSuccess),
      map(action => action.user),
      filter(user => !!user),
      concatMap((user) => from(this.userService.persistUser(user))),
      tap(() => {
        console.log('loadUserSuccess$:persistedUser');
      }),
      concatMap(() => {
        console.log('user.effects --> hasPhoneNumberVerified');
        return this.userStatusService.hasPhoneNumberVerified().pipe(
          take(1),
          tap((isUserPhoneNumberVerified) => {
            if (isUserPhoneNumberVerified) {
              return;
            }
            throw new Error('User phone number is not verified');
          })
        );
      }),
      catchError((error, caught) => {
        console.error(error);
        this.router.navigateByUrl(AuthLogout);
        return caught;
      })
    );
  }, {dispatch: false});

  loadUserFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(loadUserFailure),
      tap(action => this.sentryErrorHandlerService.handleError(action.error)),
      delay(1000), // Some time to sentry to report it
      tap(() => this.router.navigateByUrl(AuthLogout))
    ), {dispatch: false});

  updateUser$ = createEffect(
    () => this.actions$.pipe(
      ofType(updateUser),
      map(action => action.user),
      tap(user => this.userService.setUser(user)),
      concatMap((user) => from(this.userService.persistUser(user)))
    ), {dispatch: false}
  );
}
