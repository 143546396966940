import { Injectable, Injector, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { appResume, initApp } from './actions/app.actions';
import { BehaviorSubject, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { configureStyles } from '@utils/css-helpers';
import { ServiceLocator } from '@core/services/service-locator.service';


const appInitialized$ = new BehaviorSubject<boolean>(false);

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private platform: Platform,
    private ngZone: NgZone,
    // private serviceWorker: ServiceWorker,
    injector: Injector,
    private store: Store<any>) {
    this.platform.resume.subscribe(() => {
      this.ngZone.run(() => {
        this.store.dispatch(appResume());
      });
    });
    ServiceLocator.injector = injector;
    this.setCSSValues();
  }

  get appInitialized(): Observable<boolean> {
    return appInitialized$;
  }

  setInitialized() {
    appInitialized$.next(true);
  }

  async initializeApp() {
    this.store.dispatch(initApp());
  }

  // startServiceWorker() {
  // if (Capacitor.isNative) {
  //   return;
  // }
  // if (environment.production) {
  //   this.serviceWorker.init();
  // }
  // }

  dispatch(action) {
    this.store.dispatch(action);
  }

  setCSSValues() {
    configureStyles();
  }
}
