import { Injectable } from '@angular/core';
import { StatusBar as StatusBarNgx } from '@ionic-native/status-bar/ngx';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { getActiveUrl } from '../selectors/router.selectors';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Platform } from '@ionic/angular';
import { AuthLogin, AuthLogout } from '@pages/auth/auth-routes.names';

const {StatusBar} = Plugins;

const pagesWithWhiteBar = [AuthLogin, AuthLogout];

enum StatusBarPageStyle {
  LoginPage = 'LoginPage',
  CommonPages = 'CommonPages'
}

@Injectable({
  providedIn: 'root'
})
export class StatusBarService {

  isAndroid = false;

  currentStatusBarPageStyle: StatusBarPageStyle;

  /**
   * Set the background color of the status bar
   * using material design color steppers: 700
   * See https://material.io/design/color/the-color-system.html#tools-for-picking-colors
   */
  constructor(private statusBarNgx: StatusBarNgx,
              private platform: Platform,
              private store: Store<State>) {
  }

  init() {
    if (!this.platform.is('capacitor')) {
      return;
    }
    this.isAndroid = this.platform.is('android');
    this.store.select(getActiveUrl).pipe(
      switchMap((url: string) => {
        if (!url || pagesWithWhiteBar.includes(url)) {
          return from(this.setLoginPageStyle());
        }
        return from(this.setCommonPagesStyle());
      }),
      catchError(e => {
        console.log(e);
        return of(true);
      })
    ).subscribe();
    if (this.platform.is('ios')) {
      this.statusBarNgx.styleDefault();
    }
  }

  async setLoginPageStyle() {
    if (this.currentStatusBarPageStyle === StatusBarPageStyle.LoginPage) {
      return;
    }
    try {
      await StatusBar.setStyle({style: StatusBarStyle.Light});
      if (this.isAndroid) {
        await StatusBar.setBackgroundColor({color: '#ffffff'});
      }
      this.currentStatusBarPageStyle = StatusBarPageStyle.LoginPage;
    } catch (e) {
      console.log(e);
    }
  }

  async setCommonPagesStyle() {
    if (this.currentStatusBarPageStyle === StatusBarPageStyle.CommonPages) {
      return;
    }
    try {
      await StatusBar.setStyle({style: StatusBarStyle.Dark});
      if (this.isAndroid) {
        // primary 500 color: #0E47F0 (--ion-color-primary)
        // 700 color: #0032d8
        await StatusBar.setBackgroundColor({color: '#0032d8'});
      }
      this.currentStatusBarPageStyle = StatusBarPageStyle.CommonPages;
    } catch (e) {
      console.log(e);
    }
  }

}
