import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Avatar } from '../../models/avatar';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'appo-display-name',
  templateUrl: './display-name.component.html',
  styleUrls: ['./display-name.component.scss']
})
export class DisplayNameComponent {

  @Input()
  resource: Avatar;

}
