import { createAction, props } from '@ngrx/store';
import { User } from '@models/user';
import { UserBusinessV2 } from '@models/user-business-v2';

interface ICorrelationId {
  correlationId?: string;
}

export enum UserActionTypes {
  LoadUser = '[User] Load User',
  LoadUserSuccess = '[User] Load User Success',
  LoadUserFailure = '[User] Load User Failure',
  SetUser = '[User] Set User',
  UpdateUser = '[User] Update User',
  AddAdminBusinessAccount = '[User] Add Admin Business Account',
  RemoveAdminBusinessAccount = '[User] Remove Admin Business Account',
}

export const loadUser = createAction(UserActionTypes.LoadUser, props<ICorrelationId>());
export const loadUserSuccess = createAction(UserActionTypes.LoadUserSuccess, props<{ user: User } & ICorrelationId>());
export const loadUserFailure = createAction(UserActionTypes.LoadUserFailure, props<{ error: any } & ICorrelationId>());

// Set the user in the store
export const setUser = createAction(UserActionTypes.SetUser, props<{ user: User }>());

// Calls setUser and PersistUser
export const updateUser = createAction(UserActionTypes.UpdateUser, props<{ user: User }>());

export const addAdminBusinessAccount = createAction(
  UserActionTypes.AddAdminBusinessAccount,
  props<UserBusinessV2>()
);

export const removeAdminBusinessAccount = createAction(
  UserActionTypes.RemoveAdminBusinessAccount,
  props<{ accountId: string }>()
);
