import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';

const {Storage} = Plugins;

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  set(key: string, value: string): Promise<void> {
    return Storage.set({
      key,
      value
    });
  }

  async get(key: string): Promise<string> {
    const {value} = await Storage.get({key});
    return value;
  }

  removeKey(key: string): Promise<void> {
    return Storage.remove({key});
  }

  clear(): Promise<void> {
    return Storage.clear();
  }
}
