import { Injectable } from '@angular/core';
import { AccountVerifiedGuard } from '@pages/auth/guard/account-verified.guard';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FullAccountVerifiedGuard extends AccountVerifiedGuard {

  appoGuards = [
    this.sessionGuard,
    this.emailVerifiedGuard,
    this.phoneNumberGuard
  ];

  canActivate(): Observable<boolean> {
    console.log('Running FullAccountVerifiedGuard');
    return super.canActivate();
  }

}
