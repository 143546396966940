import { Directive, OnDestroy, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appoUnsubscribe]',
  standalone: true
})
export class UnsubscribeDirective implements OnDestroy {
  @Output() test: any;
  private destroySubject$ = new Subject<void>();

  // Encapsulate to prevent the subject manipulation
  get destroy$() {
    return this.destroySubject$.asObservable();
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
