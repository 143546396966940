import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RemoteConfigService } from '@core/services/remote-config/remote-config.service';
import { appResume } from '@app/actions/app.actions';
import { concatMap } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigEffects {

  constructor(private actions$: Actions,
              private remoteConfigService: RemoteConfigService) {
  }

  onAppResume$ = createEffect(
    () => this.actions$.pipe(
      ofType(appResume),
      concatMap(() => from(this.remoteConfigService.forceRefreshConfig()))
    ), {dispatch: false}
  );

}
