<ion-app>
    <ion-split-pane contentId="main-content">
        <ion-menu contentId="main-content"
                  [disabled]="!(isAuthenticated$ | async)"
                  [swipeGesture]="swipeMenuEnabled$ | async"
                  (ionWillOpen)="scrollMenuToTop()">
            <ion-header>
                <ion-toolbar>
                    <menu-header></menu-header>
                </ion-toolbar>
            </ion-header>
            <ion-content forceOverscroll="false" #menuContent>
                <ng-container *ngIf="{
                    isBusinessAccountActive: isBusinessAccountActive$ | async
                } as data">
                    <ion-list>
                        <!--                    <menu-button iconUrl="assets/icons/common/my_appointments.svg"-->
                        <!--                                 name="Mis Turnos"-->
                        <!--                                 (click)="action($event, UserView')">-->
                        <!--                    </menu-button>-->
                        <!--                    <menu-button iconUrl="assets/icons/common/past_appointments.svg"-->
                        <!--                                 name="Turnos pasados"-->
                        <!--                                 (click)="action($event)">-->
                        <!--                    </menu-button>-->
                        <menu-button
                                *ngIf="!data.isBusinessAccountActive"
                                iconUrl="assets/icons/common/account.svg"
                                name="Mis datos"
                                (click)="action($event, AccountView)">
                        </menu-button>

                        <ng-container *appoIfUserIsBusinessAdmin>
                            <menu-button
                                    *ngIf="data.isBusinessAccountActive"
                                    iconName="business-outline"
                                    name="Mi Negocio"
                                    (click)="action($event, BusinessConfigurationView)">
                            </menu-button>
                            <menu-button
                                    *ngIf="data.isBusinessAccountActive"
                                    iconName="triangle-outline"
                                    name="Subscripción"
                                    (click)="action($event, SubscriptionView)">
                            </menu-button>
                            <menu-button
                                    *ngIf="data.isBusinessAccountActive"
                                    iconUrl="assets/icons/common/clock_with_x.svg"
                                    name="Horarios Ocupados"
                                    (click)="action($event, AdminExceptions)">
                            </menu-button>
                        </ng-container>

                        <menu-button iconName="notifications-outline"
                                     name="Notificaciones"
                                     (click)="action($event, NotificationsView)">
                        </menu-button>
                        <menu-button
                                *ngIf="!data.isBusinessAccountActive"
                                iconName="time-outline"
                                name="Turnos pasados"
                                (click)="action($event, UserViewPastAppointments)">
                        </menu-button>
                        <!--                    <menu-button iconUrl="assets/icons/common/favorites.svg"-->
                        <!--                                 name="Favoritos"-->
                        <!--                                 (click)="action($event)">-->
                        <!--                    </menu-button>-->
                        <!--                    <menu-button iconUrl="assets/icons/common/settings.svg"-->
                        <!--                                 name="Configuración"-->
                        <!--                                 (click)="action($event)">-->
                        <!--                    </menu-button>-->
                        <!--                    <menu-button iconUrl="assets/icons/common/about.svg"-->
                        <!--                                 name="Acerca de"-->
                        <!--                                 (click)="action($event)">-->
                        <!--                    </menu-button>-->
                        <ng-container *appoIfUserIsBusinessAdmin>
                            <menu-button
                                    *ngIf="data.isBusinessAccountActive"
                                    iconName="download-outline"
                                    name="Exportar"
                                    (click)="action($event, AdminExportAppointments)">
                            </menu-button>
                        </ng-container>
                        <menu-button iconUrl="assets/icons/common/feedback.svg"
                                     name="Ayuda"
                                     (click)="action($event, AboutView)">
                        </menu-button>
                        <menu-button
                                *ngIf="canCreateBusinessAccount$ | async"
                                iconName="add-outline"
                                name="Registrar un negocio"
                                (click)="action($event, CreateBusinessView)">
                        </menu-button>
                        <appo-accounts-switcher
                                *ngIf="isAuthenticated$ | async">
                        </appo-accounts-switcher>
                    </ion-list>
                </ng-container>
            </ion-content>
            <ion-footer>
                <menu-button iconUrl="assets/icons/common/logout.svg"
                             name="Cerrar Sesión"
                             (click)="logout($event)"
                             class="footer-item">
                </menu-button>
            </ion-footer>
        </ion-menu>
        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
</ion-app>

