import { Action, createReducer, on } from '@ngrx/store';
import { Account } from '../models/account';
import { loadActiveAccount, loadActiveAccountFinished, setActiveAccount, updateActiveAccount } from '../actions/accounts.actions';
import { updateUser } from '@modules/user/actions/user.actions';
import { User } from '@models/user';
import { AccountType } from '@modules/accounts/models/account-type';

export const accountsFeatureKey = 'accounts';

export interface AccountsState {
  loadingActiveAccount: boolean;
  activeAccount: Account;
}

export const initialState: AccountsState = {
  loadingActiveAccount: false,
  activeAccount: void 0,
};

const reducer = createReducer(
  initialState,

  on(setActiveAccount, (state, action) => ({...state, activeAccount: action.account})),
  on(loadActiveAccount, state => ({...state, loadingActiveAccount: true})),
  on(loadActiveAccountFinished, (state, action) => ({
    ...state,
    loadingActiveAccount: false,
  })),

  on(updateActiveAccount, (state, action) => ({
    ...state,
    activeAccount: action.account
  })),

  on(updateUser, (state, action) => updateUserAccount(state, action.user)),
);

export function accountsReducer(state: AccountsState | undefined, action: Action) {
  return reducer(state, action);
}

/**
 * This should not be done in here.
 * Active account data should be loaded from the correct
 * state slice.
 * @param state
 * @param user
 */
function updateUserAccount(state: AccountsState, user: User): AccountsState {
  if (state.activeAccount?.accountType !== AccountType.User) {
    return state;
  }

  return {
    ...state,
    activeAccount: {
      ...state.activeAccount,
      pictureUrl: user.pictureUrl,
      name: user.name,
    },
  };
}
