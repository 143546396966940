import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Account } from '../../models/account';
import { AccountsService } from '../../services/accounts.service';
import { AccountType } from '../../models/account-type';
import { MenuController, NavController } from '@ionic/angular';
import { UserViewAppointments } from '@pages/user-view/pages/appointments/appointments-routes.names';
import { AppoNavigationOptions } from '@utils/animations';
import { AdminTabs } from '@pages/admin-view/admin-view-routes.names';
import { UserPermissionsService } from '@modules/accounts/services/user-permissions.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'appo-accounts-switcher',
  templateUrl: './accounts-switcher.component.html',
  styleUrls: ['./accounts-switcher.component.scss']
})
export class AccountsSwitcherComponent implements OnInit {

  accounts$: Observable<Account[]>;

  constructor(private accountsService: AccountsService,
              protected userPermissionsService: UserPermissionsService,
              private menuController: MenuController,
              private navController: NavController) {
  }

  ngOnInit(): void {
    this.accounts$ = this.accountsService.getAccountsToSwitch();
  }

  async setActiveAccount(account: Account) {
    await this.menuController.close();
    this.accountsService.switchToAccount(account);
    setTimeout(() => {
      this.navigateToAccount(account);
    });
  }

  private async navigateToAccount(account: Account) {
    let urlToNavigate = '';
    if (account.accountType === AccountType.User) {
      urlToNavigate = UserViewAppointments;
    } else {
      urlToNavigate = AdminTabs;
    }
    await this.navController.navigateRoot(urlToNavigate, AppoNavigationOptions.forward);
  }

}
