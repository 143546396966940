import { createAction, props } from '@ngrx/store';
import { RegisterForm } from '../components/register-form/register-form.interface';
import { AuthErrorCodes } from '../models/auth-error-codes';
import { LoginForm } from '../components/login-form/login-form.interface';
import { SignInProvider } from '../models/sign-in-provider';
import { RecoverPasswordForm } from '../pages/forgot-password/recover-password-form.interface';


export type LoginExtraData = LoginForm | RegisterForm
export const login = createAction(
  '[Auth] Login',
  props<{
    signInType: SignInProvider,
    extraData?: LoginExtraData
  }>()
);
export const loginSuccess = createAction('[Auth] Login Success');
export const loginFailure = createAction('[Auth] Login Failure');
export const logout = createAction('[Auth] Logout');

export const verifyEmail = createAction('[Auth] Verify Email');
export const verifyEmailSuccess = createAction('[Auth] Verify Email Success');
export const verifyEmailFailed = createAction('[Auth] Verify Email Failed');

export const checkCallbackLogin = createAction('[Auth] Check Callback Login');
export const checkCallbackLoginFinished = createAction('[Auth] Check Callback Login Finished');

export const sendVerificationEmail = createAction('[Auth] Send Verification Email');
export const sendVerificationEmailComplete = createAction('[Auth] Send Verification Email Complete');


// Register with Email & Password
export const register = createAction(
  '[Auth] Register',
  props<RegisterForm>(),
);
export const registerSuccess = createAction('[Auth] Register Success');
export const registerFailed = createAction(
  '[Auth] Register Failed',
  props<{ errorCode: AuthErrorCodes }>()
);

// Sign In With Email & Password
export const signInWithEmail = createAction(
  '[Auth] Sign In With Email',
  props<LoginForm>(),
);
export const signInWithEmailSuccess = createAction('[Auth] Sign In With Email Success');
export const signInWithEmailFailed = createAction(
  '[Auth] Sign In With Email Failed',
  props<{ errorCode: AuthErrorCodes }>()
);

// Send recover password email
export const sendRecoverPwdEmail = createAction(
  '[Auth] Send Recover Pwd Email',
  props<RecoverPasswordForm>(),
);
export const sendRecoverPwdEmailSuccess = createAction('[Auth] Send Recover Pwd Email Success');
export const sendRecoverPwdEmailFailed = createAction(
  '[Auth] Send Recover Pwd Email Failed',
  props<{ errorCode: AuthErrorCodes }>()
);
