import { ComponentFactory, ComponentFactoryResolver, createNgModule, Injectable, Injector, NgModuleRef, Type } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LazyLoadService {

  protected constructor(
    // protected compiler: Compiler,
                        protected injector: Injector,
                        protected componentFactoryResolver: ComponentFactoryResolver) {
  }

  registerModule(module: any): NgModuleRef<any> {
    return createNgModule(module, this.injector);
    // const moduleFactory = await this.compiler.compileModuleAsync(module);
    // return moduleFactory.create(this.injector);
  }

  async loadModule<M>(ngModuleRef: M): Promise<M> {
    // const module = await import(modulePath).then(m => m[moduleName]);
    await this.registerModule(ngModuleRef);
    return ngModuleRef;
  }

  async loadComponent<T>(importedComponent: Type<T>): Promise<ComponentFactory<T>> {
    // const businessInfoComponent = await import(componentPath).then(c => c[componentName]);
    return this.componentFactoryResolver.resolveComponentFactory<T>(importedComponent);
  }

  /**
   *
   * @param module loaded with import(...)
   * @param component loaded with import(...)
   */
  async load<M, T>(
    module: M,
    component: Type<T>): Promise<ComponentFactory<T>> {
    await this.loadModule<M>(module);
    return await this.loadComponent(component);
  }

}
