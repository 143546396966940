import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from './services/session.service';
import { AuthLogin } from '@pages/auth/auth-routes.names';
import { AppoGuard } from '@pages/auth/guard/appoGuard';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard  implements AppoGuard {

  constructor(
    public router: Router,
    public sessionService: SessionService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthorized().pipe(
      map(isSessionValid => {
        if (isSessionValid) {
          return true;
        }

        this.onUnauthorized();
        return false;
      })
    );
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.canActivate(route, state);
  }

  isAuthorized(): Observable<boolean> {
    console.log('running isSessionValid');
    return this.sessionService.validSession$;
  }

  async onUnauthorized() {
    await this.router.navigate([AuthLogin], {replaceUrl: true});
  }

  get name(): string {
    return 'SessionGuard';
  }

}
