import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SentryErrorHandlerService } from '../../../sentry/sentry-error-handler.service';

// const escapeStringRegexp = require('escape-string-regexp');

// const ESCAPED_PREFIXES = ['Dr.', 'Dra.', 'Dr', 'Dra'].map(prefix => escapeStringRegexp(prefix));

export interface AvatarInfo {
  name: string;
  pictureUrl: string;
  email?: string;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'appo-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {

  @Input()
  resource: AvatarInfo;

  @Input()
  size: 'small' | 'big' = 'small';

  initialChar = '';

  pictureUrl = '';

  constructor(private sentryErrorHandlerService: SentryErrorHandlerService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  configure() {
    this.pictureUrl = this.resource.pictureUrl;
    if (this.pictureUrl) {
      return;
    }
    this.setChar();
  }

  onError() {
    this.sentryErrorHandlerService.reportError(`Unable to load avatar image for ${this.resource.name}: ${this.pictureUrl}`);
    this.pictureUrl = '';
    this.setChar();
  }

  setChar() {
    const {resource} = this;

    const name = (resource.name || resource.email || '').toLocaleLowerCase();
    // const namePrefix = ESCAPED_PREFIXES.find(prefix => new RegExp(`^\\b${prefix}\\b`, 'i').test(name));
    // if (namePrefix) {
    //   this.initialChar = name.substring(namePrefix.length + 1).charAt(0); // +1 for space char
    // } else {
    //   this.initialChar = name.charAt(0);
    // }
    this.initialChar = name.charAt(0);
  }

}
