import { Action, createReducer, on } from '@ngrx/store';
import {
  addAdminBusinessAccount,
  loadUser,
  loadUserFailure,
  loadUserSuccess,
  removeAdminBusinessAccount,
  setUser
} from '../actions/user.actions';
import { User } from '@models/user';

export const userFeatureKey = 'user';

export interface UserState {
  loadingUser: boolean;
  user: User;
}

export const initialState: UserState = {
  loadingUser: false,
  user: null,
};

const userReducer = createReducer(
  initialState,

  on(loadUser, state => ({...state, loadingUser: true})),
  on(loadUserSuccess, (state, action) =>
    ({
      ...state,
      loadingUser: false,
      user: updateUserForRootBusinesses(state, action.user),
    })
  ),
  on(loadUserFailure, state => ({...state, loadingUser: false})),

  on(setUser, (state, action) => ({
    ...state,
    user: updateUserForRootBusinesses(state, action.user),
  })),

  on(addAdminBusinessAccount, (state, action) => ({
    ...state,
    user: {
      ...state.user,
      businessesV2: [
        ...state.user.businessesV2.filter(businessV2Account => businessV2Account.business.businessId !== action.business.businessId),
        action
      ],
    }
  })),
  on(removeAdminBusinessAccount, (state, action) => ({
    ...state,
    user: {
      ...state.user,
      businessesV2: state.user.businessesV2.filter(businessV2Account => businessV2Account.business.businessId !== action.accountId)
    }
  })),
);

export function reducer(state: UserState | undefined, action: Action) {
  return userReducer(state, action);
}

/**
 * Keeps root accounts alive
 */
const updateUserForRootBusinesses = (state: UserState, user: User): User => {
  if (!user) {
    return user;
  }
  const stateIsFromRootBusinesses = state.user?.businessesV2?.filter(businessV2 => businessV2.business.isFromRoot) || [];
  return {
    ...user,
    businessesV2: [
      ...user.businessesV2,
      ...stateIsFromRootBusinesses
    ]
  };
};
