import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { dismissAllModals } from '@app/actions/app.actions';
import { tap } from 'rxjs/operators';


const modals: HTMLIonModalElement[] = [];

/**
 * This effect class registers every modal that is created and dismissed.
 * This is needed because when navigating with a share link the modals
 * are not dismissed because they are not part of the navigation stack.
 */
@Injectable({
  providedIn: 'root'
})
export class ModalRegisterService {

  constructor(private actions$: Actions) {
  }

  dismissAllModals$ = createEffect(
    () => this.actions$.pipe(
      ofType(dismissAllModals),
      tap(() => this.dismissAllModals()),
    ), {dispatch: false}
  );

  static registerModal(modal: HTMLIonModalElement) {
    modals.push(modal);
  }

  static unregisterModal() {
    modals.pop();
  }

  dismissAllModals() {
    modals.forEach(modal => modal.dismiss());

    // Clear all the array
    modals.length = 0;
  }
}
