import { ChangeDetectorRef, Directive, inject, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { UserPermissionsService } from '@modules/accounts/services/user-permissions.service';
import { UnsubscribeDirective } from '@modules/unsubscribe.directive';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[appoIfUserIsBusinessAdmin]',
  hostDirectives: [{
    directive: NgIf,
    inputs: []
  }],
  providers: [UnsubscribeDirective],
  standalone: true
})
export class IfUserIsBusinessAdminDirective implements OnInit {
  private ngIfDirective = inject(NgIf);
  private destroy$ = inject(UnsubscribeDirective).destroy$;

  constructor(private userPermissionsService: UserPermissionsService,
              private changeDetectionRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.userPermissionsService.isUserAdminForActiveOnActiveBusiness$.pipe(
      takeUntil(this.destroy$),
      distinctUntilChanged(),
      tap(isAdmin => {
        this.ngIfDirective.ngIf = isAdmin;
        this.changeDetectionRef.detectChanges();
      })
    ).subscribe();
  }

}
