import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { NavController } from '@ionic/angular';
import { UserStatusService } from '@modules/user/services/user-status.service';
import { AccountPhoneNumber } from '@pages/account/account-routing.name';
import { AppoGuard } from '@pages/auth/guard/appoGuard';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
/**
 * Verifies the user has a verified phone number set on the
 * User object
 */
export class PhoneNumberGuard implements AppoGuard {

  constructor(private navController: NavController,
              private userStatusService: UserStatusService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthorized().pipe(
      map(isPhoneNumberVerified => {
        if (isPhoneNumberVerified) {
          return true;
        }
        this.onUnauthorized();
        return false;
      })
    );
  }

  isAuthorized(): Observable<boolean> {
    console.log('running isPhoneNumberVerified');
    return this.userStatusService.hasPhoneNumberVerified();
  }

  async onUnauthorized() {
    await this.navController.navigateRoot(AccountPhoneNumber, {
      animated: true,
      animationDirection: 'forward'
    });
  }

  get name(): string {
    return 'PhoneNumberGuard';
  }

}
