import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userFeatureKey, UserState } from '../reducers/user.reducer';

export const selectUserState = createFeatureSelector<UserState>(userFeatureKey);

export const getUser = createSelector(
  selectUserState,
  (state: UserState) => state.user,
);

// export const isLoadingUser = createSelector(
//   selectUserState,
//   state => state.loadingUser,
// );
