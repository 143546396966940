import { createFeatureSelector, createSelector } from '@ngrx/store';
import { accountsFeatureKey, AccountsState } from '../reducer/accounts.reducer';

const selectAccountsState = createFeatureSelector<AccountsState>(accountsFeatureKey);

export const selectLoadingActiveAccount = createSelector(
  selectAccountsState,
  state => state.loadingActiveAccount,
);

export const selectActiveAccount = createSelector(
  selectAccountsState,
  state => state.activeAccount
);
