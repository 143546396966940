import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterStateUrl } from '../reducers/router';

export const selectRouterState = createFeatureSelector<{ state: RouterStateUrl }>('router');

export const getActiveUrl = createSelector(
  selectRouterState,
  routerState => routerState?.state?.url
);

export const selectQueryParams = createSelector(
  selectRouterState,
  state => state?.state?.queryParams
);

export const selectFragment = createSelector(
  selectRouterState,
  state => state?.state?.fragment
);
