import { Component, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { AppService } from './app.service';
import { IonContent, MenuController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { AuthLogout } from '@pages/auth/auth-routes.names';
import { AboutView } from '@pages/about/about-routes.names';
import { AccountsService } from '@modules/accounts/services/accounts.service';
import { SessionService } from '@core/session/services/session.service';
import { map, shareReplay } from 'rxjs/operators';
import { NotificationsView } from '@pages/notifications/notifications-routes.names';
import { BusinessSubscriptions } from '@pages/admin-view/pages/subscriptions/subscriptions-routes.names';
import { AccountsPage } from '@pages/account/account-routing.name';
import { AdminExceptions } from '@pages/admin-view/pages/business-exceptions/business-exceptions-routes.names';
import { RegisterBusinessService } from '@pages/register-business/services/register-business.service';
import { BusinessConfigurationView } from '@pages/admin-view/pages/business-configuration/business-configuration-routes.names';
import { CreateBusinessView } from '@pages/register-business/pages/create-business/create-business-routes.names';
import { UserViewPastAppointments } from '@pages/user-view/pages/past-appointment/past-appointments-routes.names';
import { AdminExportAppointments } from '@pages/admin-view/pages/business-export-appointments/business-export-appointments-routes.names';
import { AppMinimumVersionService } from '@core/services/app-minimum-version.service';
import { AppoNavigationOptions } from '@utils/animations';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('menuContent')
  ionContent: IonContent;

  AboutView = AboutView;

  NotificationsView = NotificationsView;

  CreateBusinessView = CreateBusinessView;

  AccountView = AccountsPage;

  SubscriptionView = BusinessSubscriptions;

  AdminExceptions = AdminExceptions;

  BusinessConfigurationView = BusinessConfigurationView;

  UserViewPastAppointments = UserViewPastAppointments;

  AdminExportAppointments = AdminExportAppointments;

  isAuthenticated$: Observable<boolean>;

  isBusinessAccountActive$: Observable<boolean>;

  canCreateBusinessAccount$: Observable<boolean>;

  swipeMenuEnabled$: Observable<boolean>;

  constructor(
    private appService: AppService,
    private sessionService: SessionService,
    private menuController: MenuController,
    private router: Router,
    private navController: NavController,
    private accountsService: AccountsService,
    private registerBusinessService: RegisterBusinessService,
    private appMinimumVersionService: AppMinimumVersionService
  ) {
    this.isAuthenticated$ = this.sessionService.validSession$;
    this.appService.initializeApp();
  }

  ngOnInit() {
    this.isBusinessAccountActive$ = this.accountsService.isBusinessAccountActive().pipe(
      shareReplay(1)
    );
    this.canCreateBusinessAccount$ = this.registerBusinessService.canCreateBusinessAccount();

    this.swipeMenuEnabled$ = combineLatest([
      this.isAuthenticated$,
      this.appMinimumVersionService.appMeetsMinimumVersion()
    ]).pipe(
      map(([hasValidSession, appHasMinimumVersion]) => hasValidSession && appHasMinimumVersion)
    );
  }

  async logout($event) {
    $event.preventDefault();
    await this.menuController.toggle();
    await this.router.navigateByUrl(AuthLogout);
  }

  async action($event, url = '') {
    $event.preventDefault();
    await this.menuController.toggle();
    if (url) {
      await this.navController.navigateForward(url, AppoNavigationOptions.forward);
    }
  }

  async scrollMenuToTop(): Promise<void> {
    return this.ionContent?.scrollToTop();
  }

}
