import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppInitializedGuard } from './app-initialized.guard';
import { AdminView } from '@pages/admin-view/admin-view-routes.names';
import { UserView } from '@pages/user-view/user-view-routes.names';
import { Auth } from '@pages/auth/auth-routes.names';
import { Account } from '@pages/account/account-routing.name';
import { SelectAccount } from '@app/app-routes.names';
import { About } from '@pages/about/about-routes.names';
import { Share } from '@pages/share/share-routes.names';
import { Notifications } from '@pages/notifications/notifications-routes.names';
import { Welcome } from '@pages/welcome/welcome-routing.names';
import { RegisterBusiness } from '@pages/register-business/register-business-routes.names';
import { SelectAccountTypeResolver } from '@modules/accounts/resolvers/select-account-type-resolver.service';
import { FullAccountVerifiedGuard } from '@pages/auth/guard/full-account-verified.guard';
import { SessionAndEmailGuard } from '@pages/auth/guard/session-and-email.guard';
import { CancelAppointmentPublic } from '@pages/cancel-appointment-public/cancel-appointment-public-routing.names';
import { AppMinimVersion } from '@pages/app-minimum-version/app-minimum-version-routing.names';
import { AppHasMinimumVersionGuard } from '@core/guards/app-has-minimum-version.guard';
import { MercadoPago } from '@pages/mercado-pago/mercado-pago-routes.names';


const routes: Routes = [
  {
    path: '',
    redirectTo: `/${SelectAccount}`,
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [AppInitializedGuard, AppHasMinimumVersionGuard],
    children: [
      {
        path: Auth,
        loadChildren: () => import('@pages/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: About,
        loadChildren: () => import('@pages/about/about.module').then(m => m.AboutModule)
      },
      {
        canActivate: [SessionAndEmailGuard],
        path: Account,
        loadChildren: () => import('@pages/account/account.module').then(m => m.AccountModule)
      },
      {
        path: '',
        canActivate: [FullAccountVerifiedGuard],
        children: [
          {
            path: SelectAccount,
            resolve: {
              resolve: SelectAccountTypeResolver,
            },
            children: [],
          },
          {
            path: AdminView,
            loadChildren: () => import('@pages/admin-view/admin-view.module').then(m => m.AdminViewModule)
          },
          {
            path: Notifications,
            loadChildren: () => import('@pages/notifications/notifications.module').then(m => m.NotificationsModule)
          },
          {
            path: RegisterBusiness,
            loadChildren: () => import('@pages/register-business/register-business.module').then(m => m.RegisterBusinessModule)
          },
          {
            path: Welcome,
            loadChildren: () => import('@pages/welcome/welcome.module').then(m => m.WelcomeModule)
          },
        ]
      },
      {
        path: Share,
        loadChildren: () => import('@pages/share/share.module').then(m => m.ShareModule)
      },
      {
        path: UserView,
        loadChildren: () => import('@pages/user-view/user-view.module').then(m => m.UserViewModule)
      },
      {
        path: CancelAppointmentPublic,
        loadChildren: () =>
          import('@pages/cancel-appointment-public/cancel-appointment-public.module').then(m => m.CancelAppointmentPublicModule)
      },
      {
        path: AppMinimVersion,
        loadChildren: () => import('@pages/app-minimum-version/app-minimum-version.module').then(m => m.AppMinimumVersionModule)
      },
      {
        path: MercadoPago,
        loadChildren: () => import('@pages/mercado-pago/mercado-pago.module').then(m => m.MercadoPagoModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: SelectAccount,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      bindToComponentInputs: true,
    })
    // RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
