import { logout } from '../../pages/auth/actions/auth.actions';

const LOGOUT_TYPE_ACTION = logout().type;

export function resetApp(reducer) {
  return function newReducer(state, action) {
    if (action.type === LOGOUT_TYPE_ACTION) {
      state = undefined;
    }
    return reducer(state, action);
  };
}

export const getAppMetaReducers = () => [resetApp];
