import { Injectable } from '@angular/core';
import { AppSyncClient } from '@core/services/app-sync/app-sync-client.service';
import Me from '../../../graphql/queries/me';
import { GetMe, GetMe_me_businessesV2, GetMe_me_businessesV2_business } from '@graphql/types';
import { FirebaseUser, User } from '@models/user';
import { Observable, of } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getUser } from '../selectors/user.selectors';
import { FirebaseService } from '@core/services/firebase.service';
import { sendVerificationEmail, verifyEmail } from '@pages/auth/actions/auth.actions';
import {
  addAdminBusinessAccount,
  loadUser,
  loadUserFailure,
  loadUserSuccess,
  removeAdminBusinessAccount,
  setUser,
  updateUser,
  UserActionTypes
} from '../actions/user.actions';
import { SessionService } from '@core/session/services/session.service';
import { StorageService } from '@core/services/storage.service';
import { SignInProvider } from '@pages/auth/models/sign-in-provider';
import { UserState } from '../reducers/user.reducer';
import { SentryErrorHandlerService } from '@modules/sentry/sentry-error-handler.service';
import firebase from 'firebase';
import { getRandomIDSync } from '@utils/id.utils';
import { Actions, ofType } from '@ngrx/effects';
import IdTokenResult = firebase.auth.IdTokenResult;
import UserCredential = firebase.auth.UserCredential;

const USER_KEY = 'user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private appSyncClient: AppSyncClient,
              private sessionService: SessionService,
              private store: Store<UserState>,
              private sentryErrorHandlerService: SentryErrorHandlerService,
              private storage: StorageService,
              private actions$: Actions) {
  }

  dispatchLoadUser(correlationId?: string) {
    this.store.dispatch(loadUser({correlationId: correlationId || getRandomIDSync()}));
  }

  addAdminBusinessAccount(meMeBusinessesV2Business: GetMe_me_businessesV2_business) {
    this.store.dispatch(addAdminBusinessAccount({
      __typename: 'UserBusiness',
      business: meMeBusinessesV2Business,
      readOnly: false
    }));
  }

  removeAdminBusinessAccount(accountId: string) {
    this.store.dispatch(removeAdminBusinessAccount({accountId}));
  }

  fetchUser(): Observable<User> {
    return this.appSyncClient.query<GetMe>(Me).pipe(
      map((response: GetMe) => response.me)
    );
  }

  getUser(): Observable<User> {
    return this.store.select(getUser);
  }

  getFirebaseUser(): Promise<FirebaseUser> {
    return FirebaseService.getCurrentUser();
  }

  verifyEmail() {
    this.store.dispatch(verifyEmail());
  }

  async getUserSignedInProvider(): Promise<SignInProvider | undefined> {
    const firebaseUser: FirebaseUser = await this.getFirebaseUser();
    if (!firebaseUser) {
      return undefined;
    }
    const idTokenResult: IdTokenResult = await firebaseUser.getIdTokenResult();
    return idTokenResult.signInProvider as SignInProvider;
  }

  async refreshToken(): Promise<void> {
    await this.sessionService.refreshToken();
    const user = await FirebaseService.getCurrentUser();
    await user.reload();
  }

  dispatchSendVerificationEmail() {
    this.store.dispatch(sendVerificationEmail());
  }

  async sendVerificationEmail(): Promise<void> {
    return await (await FirebaseService.getCurrentUser()).sendEmailVerification();
  }

  persistUser(user: User): Promise<void> {
    return this.storage.set(USER_KEY, JSON.stringify(user));
  }

  async loadUser(): Promise<void> {
    const user = await this.storage.get(USER_KEY);
    try {
      const userParsed: User = JSON.parse(user);
      if (userParsed) {
        this.setUser(this.cleanUserForLatestVersion(userParsed));
      }
    } catch (e) {
      console.log('Error parsing user from store: ', e);
    }
  }

  private cleanUserForLatestVersion(user: User & { businesses?: GetMe_me_businessesV2_business[] }): User {
    if ('businesses' in user) {
      return {
        ...user,
        businessesV2: (user.businesses ?? []).map((business): GetMe_me_businessesV2 => ({
          business,
          readOnly: false,
          __typename: 'UserBusiness'
        }))
      };
    }
    return user;
  }

  // isLoadingUser(): Observable<boolean> {
  //   return this.store.select(isLoadingUser);
  // }

  setUser(user: User) {
    this.store.dispatch(setUser({user}));
  }

  updateUser(user: User) {
    this.store.dispatch(updateUser({user}));
  }

  /**
   * If the update to Firebase fails, the error is caught.
   * The displayName will be set on the server based on a
   * displayName for a given anonymous appointment or if there
   * isn't any will be set to 'Anonimo'. This way the register flow
   * is not broken. The user can update the display name later
   * in the account page.
   * @param userCredential credentials
   * @param name Display name of the user
   */
  async updateFirebaseDisplayName(userCredential: UserCredential, name: string): Promise<void> {
    try {
      await userCredential.user.updateProfile({displayName: name});
    } catch (e) {
      this.sentryErrorHandlerService.handleError(e, `Error updating user.displayName with '${name}' for firebase userId '${userCredential.user.uid}'`);
    }
  }

  /**
   * Refreshes the User by dispatching loadUser.
   * Then waits for the loadUser (success | failure) to be
   * dispatched.
   * @returns The refreshed User or throws an error.
   */
  refreshUser(): Observable<User> {
    const correlationId = getRandomIDSync();
    this.dispatchLoadUser(correlationId);
    return this.actions$.pipe(
      ofType(loadUserSuccess, loadUserFailure),
      filter(action => action.correlationId === correlationId),
      first(),
      map((action) => {
        if (action.type === UserActionTypes.LoadUserSuccess) {
          return action.user;
        }
        throw new Error(action.error);
      }),
    );
  }

  isActiveUser(userId: string | undefined): Observable<boolean> {
    if (!userId) {
      return of(false);
    }

    return this.getUser().pipe(
      map(user => user.userId === userId),
    );
  }

}

