import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { accountsFeatureKey, accountsReducer } from './reducer/accounts.reducer';
import { EffectsModule } from '@ngrx/effects';
import { AccountsEffects } from './effects/accounts.effects';
import { AccountsSwitcherComponent } from './components/accounts-switcher/accounts-switcher.component';
import { IonicModule } from '@ionic/angular';
import { AccountComponent } from './components/account/account.component';
import { AvatarModule } from '../avatar/avatar.module';
import { AccountLogoComponent } from './components/account-logo/account-logo.component';
import { BusinessAvatarModule } from '../business-avatar/business-avatar.module';
import { UserProfileTypeComponent } from '@modules/user-profile-type/user-profile-type.component';


@NgModule({
  declarations: [AccountsSwitcherComponent, AccountComponent, AccountLogoComponent],
  exports: [
    AccountsSwitcherComponent,
    AccountLogoComponent,
    AccountComponent
  ],
  imports: [
    CommonModule,
    StoreModule.forFeature(accountsFeatureKey, accountsReducer),
    EffectsModule.forFeature([AccountsEffects]),
    IonicModule,
    AvatarModule,
    BusinessAvatarModule,
    UserProfileTypeComponent,
  ]
})
export class AccountsModule {
}
