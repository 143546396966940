import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisplayNameComponent } from './components/display-name/display-name.component';
import { AvatarsListComponent } from './components/avatars-list/avatars-list.component';
import { AvatarComponent } from './components/avatar/avatar.component';


@NgModule({
  declarations: [AvatarComponent, AvatarsListComponent, DisplayNameComponent],
  exports: [AvatarComponent, AvatarsListComponent, DisplayNameComponent],
  imports: [
    CommonModule
  ]
})
export class AvatarModule {
}

