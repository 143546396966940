import { from, Observable, of } from 'rxjs';
import { EmailVerifiedGuard } from '@pages/auth/guard/email-verified.guard';
import { catchError, concatMap, first, last, mapTo, switchMap, takeWhile, tap } from 'rxjs/operators';
import { SessionGuard } from '@core/session/session.guard';
import { PhoneNumberGuard } from '@pages/account/guards/phone-number.guard';
import { RedirectService } from '@core/services/redirect.service';
import { Inject, Injectable } from '@angular/core';
import { AppoGuard } from '@pages/auth/guard/appoGuard';
import { NavController } from '@ionic/angular';
import { SentryErrorHandlerService } from '@modules/sentry/sentry-error-handler.service';
import { Router } from '@angular/router';
import { SPLASH_SCREEN_SERVICE } from '@core/services/splash-screen/splash-screen-factory';
import { SplashScreenHandler } from '@core/services/splash-screen/splash-screen-handler';
import { AuthLogout } from '@pages/auth/auth-routes.names';

@Injectable({
  providedIn: 'root'
})
export abstract class AccountVerifiedGuard {

  protected appoGuards: AppoGuard[] = [];

  constructor(protected redirect: RedirectService,
              protected sentryErrorHandlerService: SentryErrorHandlerService,
              protected navController: NavController,
              protected sessionGuard: SessionGuard,
              protected emailVerifiedGuard: EmailVerifiedGuard,
              protected router: Router,
              @Inject(SPLASH_SCREEN_SERVICE) private splashScreenService: SplashScreenHandler,
              protected phoneNumberGuard: PhoneNumberGuard) {
  }

  canActivate(): Observable<boolean> {
    console.group('Running isAccountVerified');
    const destinationUrl = this.router.getCurrentNavigation().initialUrl.toString();
    console.log('destinationUrl: ', destinationUrl);

    return from(this.appoGuards).pipe(
      // For each guard, fire isAuthorized and wait for it to complete.
      concatMap((guard) => {
        return guard.isAuthorized().pipe(
          first(),
          tap(isAuthorized => {
            console.log(`Guard ${guard.name} is ${isAuthorized}`);
          }),
          switchMap(isAuthorized => {
            if (!isAuthorized) {
              return from(this.redirect.saveRedirectUrl(destinationUrl)
                .then(() => this.splashScreenService.hide())
                .then(() => guard.onUnauthorized())).pipe(mapTo(false));
            }
            return of(true);
          }),
        );
      }),
      // Don't execute the next guard if the current guard's
      // result is not true.
      takeWhile((isAuthorized) => {
        return isAuthorized === true;
      }, /* inclusive */ true),

      // Return the last guard's result.
      last(),
      tap(() => {
        console.groupEnd();
      }),
      catchError((e) => {
        console.groupEnd();
        this.sentryErrorHandlerService.handleError(e, 'AccountVerifiedGuard');
        this.navController.navigateRoot(AuthLogout);
        return of(false);
      }),
    );
  }
}

