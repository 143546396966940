export const Auth = 'auth';
export const Login = 'login';
export const Logout = 'logout';
export const VerifyEmail = 'verify-email';
export const ForgotPassword = 'forgot-password';


export const AuthLogin = `/${Auth}/${Login}`;
export const AuthLogout = `/${Auth}/${Logout}`;
export const AuthVerifyEmail = `/${Auth}/${VerifyEmail}`;
export const AuthForgotPassword = `/${Auth}/${ForgotPassword}`;
