import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, filter, first, map, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { combineLatest, from, Observable, of } from 'rxjs';
import { User } from '@models/user';
import { appInitialized, initApp } from './actions/app.actions';
import { Platform } from '@ionic/angular';
import { AppService } from './app.service';
import { UserService } from '@modules/user/services/user.service';
import { AccountsService } from '@modules/accounts/services/accounts.service';
import { StatusBarService } from '@core/services/status-bar.service';
import { FirebaseService } from '@core/services/firebase.service';
import { SessionService } from '@core/session/services/session.service';
import { SentryErrorHandlerService } from '@modules/sentry/sentry-error-handler.service';
import { AnalyticsService } from '@modules/analytics/analytics.service';
import { DeepLinkingService } from '@core/services/deep-linking.service';
import { HandleSharedLinkService } from '@pages/share/services/handle-shared-link-service';
import { NotificationsSetupService } from '@core/services/notifications-setup.service';
import { Capacitor } from '@capacitor/core';
import { APPOINTIA_VERSION } from '@utils/version';
import { BrowserService } from '@core/services/browser.service';
import { SplashScreenHandler } from '@core/services/splash-screen/splash-screen-handler';
import { SPLASH_SCREEN_SERVICE } from '@core/services/splash-screen/splash-screen-factory';
import { PostHogService } from '@core/services/post-hog.service';
import { environment } from '@env/environment';
import { RemoteConfigService } from '@core/services/remote-config/remote-config.service';


@Injectable({
  providedIn: 'root'
})
export class AppEffects {

  constructor(
    private appService: AppService,
    public actions$: Actions,
    private platform: Platform,
    private statusBarService: StatusBarService,
    private postHogService: PostHogService,
    public sentryErrorHandlerService: SentryErrorHandlerService,
    private analyticsService: AnalyticsService,
    private userService: UserService,
    private accountsService: AccountsService,
    private sessionService: SessionService,
    @Inject(SPLASH_SCREEN_SERVICE) private splashScreenService: SplashScreenHandler,
    private handleSharedLinkService: HandleSharedLinkService,
    private deepLinkingService: DeepLinkingService,
    private notificationsSetupService: NotificationsSetupService,
    private browserService: BrowserService,
    private remoteConfigService: RemoteConfigService) {
  }

  initApp$ = createEffect(
    () => this.actions$.pipe(
      ofType(initApp),
      concatMap(() => from(Promise.all([this.platform.ready(), FirebaseService.initialize()]))),
      concatMap(() => from(Promise.all([
        this.notificationsSetupService.initModule(),
        this.deepLinkingService.loadShareModule(),
        this.userService.loadUser(),
        this.remoteConfigService.init(),
      ]))),
      tap(() => {
        this.accountsService.loadActiveAccount();
        this.statusBarService.init();
      }),
      concatMap(() => from(this.sessionService.init()).pipe(
        // trigger refresh user if a valid session exists (has saved a jwt token)
        concatMap(() => from(this.sessionService.hasValidSession()).pipe(
          tap(hasValidSession => {
            if (hasValidSession) {
              this.userService.dispatchLoadUser();
            }
          }),
          first(),
        )),
      )),
      // Listen for tapped notifications after knowing the status of the session
      tap(() => this.notificationsSetupService.listenForTapNotifications()),
      concatMap(() => this.accountsService.isLoadingActiveAccount().pipe(
          filter(activeAccountLoaded => !activeAccountLoaded),
          take(1),
        )
      ),
      map(appInitialized),
    )
  );

  appInitialized$ = createEffect(
    () => this.actions$.pipe(
      ofType(appInitialized),
      tap(() => this.appService.setInitialized()),
      tap(() => this.removeSW()),
      concatMap(() => this.shouldHideSlash().pipe(
        take(1),
        map((shouldHideSplash) => {
          return shouldHideSplash ? from(this.splashScreenService.hide()) : of(shouldHideSplash);
        })
      )),
      tap(() => {
        this.deepLinkingService.listenForLinks();
        this.listenSessionForThirdParties();
        // this.appService.startServiceWorker();

      }),
    ), {dispatch: false}
  );

  listenSessionForThirdParties() {
    this.sessionService.validSession$.pipe(
      filter(() => environment.production),
      tap((validSession) => {
        this.postHogService.init();
        AnalyticsService.initAnalytics();
      }),
      filter(validSession => validSession),
      switchMap(() => this.userService.getUser().pipe(
        filter(user => !!user),
        tap(user => {
          this.userService.getFirebaseUser().then(firebaseUser => {
            this.postHogService.identifyUser(user.userId, {
              appointiaEmail: user.email,
              appointiaName: user.name,
              appointiaPhoneNumber: user.phoneNumber,
              appointiaFirebaseUserData: {
                uid: firebaseUser.uid,
                email: firebaseUser.email,
                providerData: firebaseUser.providerData
              },
              appointiaVersion: APPOINTIA_VERSION
            }, undefined);
          });
        }),
        switchMap((user) => combineLatest([
          this.initThirdParties$(user),
        ])),
      ))
    ).subscribe();
  }

  initThirdParties$(user: User): Observable<void> {
    return of(undefined).pipe(
      concatMap(() => from(this.sessionService.getDecodedToken())),
      tap((decodedToken) => {
        const browserInfo = this.browserService.getBrowserInfo();
        this.sentryErrorHandlerService.setUser(user);
        this.sentryErrorHandlerService.setDevice(Capacitor.platform);
        this.sentryErrorHandlerService.setContext('decodedToken', decodedToken);
        this.analyticsService.setUserId(user.userId);
        this.analyticsService.setUserProps('appVersion', APPOINTIA_VERSION);
        this.analyticsService.setUserProps('platform', Capacitor.platform);
        this.analyticsService.setUserProps('browserInfo', browserInfo);
        this.analyticsService.logEvent(browserInfo);
      }),
      mapTo(undefined),
    );
  }

  isHandlingDeepLink(): Observable<boolean> {
    return combineLatest([
      this.handleSharedLinkService.isHandlingSharedLink(),
      from(this.deepLinkingService.wasAppInitializedFromDeepLink())
    ]).pipe(
      tap(([isHandlingSharedLink, wasAppInitializedFromDeepLink]) => {
        console.log('app::isHandlingSharedLink: ', isHandlingSharedLink);
        console.log('app::wasAppInitializedFromDeepLink: ', wasAppInitializedFromDeepLink);
      }),
      map(([isHandlingSharedLink, wasAppInitializedFromDeepLink]) => isHandlingSharedLink || wasAppInitializedFromDeepLink),
      tap(res => console.log('isHandlingDeepLink on Init: ', res)),
    );
  }

  shouldHideSlash(): Observable<boolean> {
    return combineLatest([
      this.notificationsSetupService.isProcessingNotification(),
      this.isHandlingDeepLink(),
    ]).pipe(
      map(([isProcessingNotification, isHandlingDeepLink]) => {
        return !isProcessingNotification && !isHandlingDeepLink;
      }),
      tap(shouldHideSplashScreen => {
        console.log('app:shouldHideSplashScreen: ', shouldHideSplashScreen);
      })
    );
  }

  removeSW() {
    if (!navigator.serviceWorker || !navigator.serviceWorker.getRegistrations) {
      return;
    }
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        console.log('Removing SW...');
        try {
          registration.unregister?.().catch(e => {
            this.sentryErrorHandlerService.handleError('CATCH: Error Removing SW', e);
          });
        } catch (e) {
          this.sentryErrorHandlerService.handleError('GLOBAL Error Removing SW', e);
        }
      }
    });
  }

}
