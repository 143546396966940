import { Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { SplashScreenHandler } from '@core/services/splash-screen/splash-screen-handler';

const {SplashScreen} = Plugins;

@Injectable({
  providedIn: 'root'
})
export class SplashScreenNativeService implements SplashScreenHandler {

  show(): Promise<void> {
    return SplashScreen.show({
      autoHide: false,
      fadeInDuration: 0,
    });
  }

  hide(): Promise<void> {
    return SplashScreen.hide();
  }

}
