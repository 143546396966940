import { DisplayName } from '@pages/account/pages/change-display-name/change-display-name.names';

export const Account = 'account';
export const PhoneNumber = 'phone-number';
export const Verify = 'verify';
export const DeleteAccount = 'delete-account';
export const AccountsPage = `/${Account}`;
export const AccountPhoneNumber = `/${Account}/${PhoneNumber}`;
export const AccountPhoneNumberVerify = `${AccountPhoneNumber}/${Verify}`;
export const ChangeDisplayName = `${Account}/${DisplayName}`;
