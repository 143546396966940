import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { Account } from '@modules/accounts/models/account';
import { AccountType } from '@modules/accounts/models/account-type';
import { UserViewAppointments } from '@pages/user-view/pages/appointments/appointments-routes.names';
import { AdminView } from '@pages/admin-view/admin-view-routes.names';
import { BusinessAppointments } from '@pages/admin-view/pages/business-appointments/business-appointments-routes.names';
import { from, Observable } from 'rxjs';
import { AccountsService } from '@modules/accounts/services/accounts.service';
import { SentryErrorHandlerService } from '@modules/sentry/sentry-error-handler.service';
import { LoginService } from '@pages/auth/services/login.service';
import { RedirectService } from '@core/services/redirect.service';

@Injectable({
  providedIn: 'root'
})
export class SelectAccountTypeResolver {

  constructor(private accountsService: AccountsService,
              private sentryErrorHandlerService: SentryErrorHandlerService,
              private loginService: LoginService,
              private redirectService: RedirectService,
              private router: Router) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<void> {
    return this.accountsService.getActiveAccount().pipe(
      switchMap((activeAccount: Account) => from(this.redirectService.getRedirectUrl()).pipe(
        map((redirectUrl: string) => {
          console.group('******* Select Account Type Resolver');
          console.log('redirect url: ', redirectUrl);
          console.log('activeAccount.accountType: ', activeAccount.accountType);
          console.log('AppResolver:activeAccount: ', activeAccount);

          if (!activeAccount) {
            console.log('**** ALERT: THIS SHOULD NEVER HAPPEN!!! ****');
            console.groupEnd();
            this.sentryErrorHandlerService.reportError('[SelectAccount.resolver] No active account');
            this.loginService.logout();
            return;
          }

          if (redirectUrl) {
            console.groupEnd();
            this.redirectService.deleteRedirectUrl();
            this.router.navigateByUrl(redirectUrl);
            return;
          }

          console.groupEnd();
          if (activeAccount.accountType === AccountType.User) {
            // User Account
            this.router.navigateByUrl(UserViewAppointments);
          } else {
            // Business account
            this.router.navigateByUrl(`/${AdminView}/tabs/${BusinessAppointments}`);
          }
          return undefined;
        }),
      ))
    );
  }

}
