import { Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';

const REDIRECT_URL_KEY = 'redirect_url';

@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  constructor(private storageService: StorageService) {
  }

  saveRedirectUrl(url: string): Promise<void> {
    console.log('---- RedirectService.saveRedirectUrl: ', url);
    return this.storageService.set(REDIRECT_URL_KEY, url);
  }

  async getRedirectUrl(): Promise<string | undefined> {
    const url = await this.storageService.get(REDIRECT_URL_KEY);
    console.log('---- RedirectService.getRedirectUrl: ', url);
    return url;
  }

  deleteRedirectUrl(): Promise<void> {
    console.log('---- RedirectService.deleteRedirectUrl');
    return this.storageService.removeKey(REDIRECT_URL_KEY);
  }
}
