import { Injectable, NgModuleRef } from '@angular/core';
import { LazyLoadService } from '@core/services/lazy-load.service';
import { NotificationsModule } from '@pages/notifications/notifications.module';
import { LOCAL_NOTIFICATION_TOKEN, NOTIFICATION_TOKEN, PUSH_NOTIFICATION_TOKEN } from '@modules/notifications/inection-tokens';
import { PushNotificationsService } from '@modules/notifications/services/push-notifications.service';
import { LocalNotificationsService } from '@modules/notifications/services/local-notifications.service';
import { Observable, of } from 'rxjs';
import { NotificationsService } from '@modules/notifications/services/notifications.service';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NotificationsSetupService {
  private isCapacitorApp = false;

  private isListening = false;

  private notificationsModule: NgModuleRef<NotificationsModule>;

  constructor(private lazyLoadService: LazyLoadService,
              private platform: Platform) {
    this.isCapacitorApp = this.platform.is('capacitor');
  }

  private async loadModule(): Promise<NgModuleRef<NotificationsModule>> {
    const notificationsModule = await import('../../modules/notifications/notifications.module').then(m => m.NotificationsModule);
    return await this.lazyLoadService.registerModule(notificationsModule);
  }

  async initModule(): Promise<void> {
    if (!this.isCapacitorApp) {
      return;
    }
    try {
      this.notificationsModule = await this.loadModule();
    } catch (e) {
      console.error('Error loading Notifications module: ', e);
    }
  }

  get notificationService(): NotificationsService {
    return this.notificationsModule.injector.get(NOTIFICATION_TOKEN) as NotificationsService;
  }

  get pushNotificationService(): PushNotificationsService {
    return this.notificationsModule.injector.get(PUSH_NOTIFICATION_TOKEN) as PushNotificationsService;
  }

  get localNotificationService(): LocalNotificationsService {
    return this.notificationsModule.injector.get(LOCAL_NOTIFICATION_TOKEN) as LocalNotificationsService;
  }

  listenForTapNotifications() {
    if (this.isCapacitorApp) {
      this.pushNotificationService.listenForActionPerformedNotification();
      this.localNotificationService.listenForActionPerformedNotification();
    }
  }

  isProcessingNotification(): Observable<boolean> {
    if (this.isCapacitorApp) {
      return this.notificationService.isProcessingNotification();
    }
    return of(false);
  }

  async listenForNotifications() {
    if (this.isListening) {
      return;
    }
    try {
      await this.pushNotificationService.init();
      await this.localNotificationService.init();
      this.isListening = true;
    } catch (e) {
      console.error('Error setting up Notifications module: ', e);
    }
  }

  stopListeningForNotifications() {
    this.setNotListening();
    this.pushNotificationService.removeAllListeners();
    this.localNotificationService.removeAllListeners();
  }

  setNotListening() {
    this.isListening = false;
  }
}
