import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { UserStatusService } from '@modules/user/services/user-status.service';
import { AuthVerifyEmail } from '@pages/auth/auth-routes.names';
import { AppoGuard } from '@pages/auth/guard/appoGuard';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EmailVerifiedGuard implements AppoGuard {

  constructor(private navController: NavController,
              private userStatusService: UserStatusService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {
    return this.isAuthorized().pipe(
      map(isEmailVerified => {
        if (isEmailVerified) {
          return true;
        }
        this.onUnauthorized();
        return false;
      })
    );
  }

  isAuthorized(): Observable<boolean> {
    console.log('running isEmailVerified');
    return this.userStatusService.hasEmailVerified();
  }

  async onUnauthorized() {
    await this.navController.navigateRoot(AuthVerifyEmail, {
      animated: true,
      animationDirection: 'forward'
    });
  }

  get name(): string {
    return 'EmailVerifiedGuard';
  }

}
