import { environment } from '@env/environment';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/remote-config';
import 'firebase/storage';
import { FirebaseUser } from '@models/user';
import { getDefaultConfig, getDefaultConfigSettings } from '@core/services/remote-config/default-config';

export type RemoteConfig = firebase.remoteConfig.RemoteConfig;
export type RemoteConfigValue = firebase.remoteConfig.Value;
export type RemoteConfigValues = { [key: string]: RemoteConfigValue };
export type RemoteConfigDefaultValues = RemoteConfig['defaultConfig'];

let firebaseApp: firebase.app.App;

export class FirebaseService {

  static getApp(): firebase.app.App {
    return firebaseApp;
  }

  /**
   * Initializes Firebase and firebase auth.
   * It doesn't wait for firebase auth to be ready.
   * The method getCurrentUser already ensures firebase auth
   * is initialized before returning the user.
   */
  private static async initializeApp(): Promise<void> {
    // Initialize firebase
    firebaseApp = firebase.initializeApp(environment.firebase.config);

    // Initialize firebase auth
    const auth = firebaseApp.auth();
    auth.languageCode = 'es';
    await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    // Initialize Remote Config
    await FirebaseService.setupRemoteConfig();
  }

  static initialize(): Promise<void> {
    return FirebaseService.initializeApp();
  }

  static getFirebaseStorage(): firebase.storage.Storage {
    return firebase.storage();
  }

  private static getAuthState(): Promise<FirebaseUser | null> {
    return new Promise((resolve, reject) => {
      const unsubscribe = firebaseApp.auth().onAuthStateChanged(
        (user) => {
          resolve(user);
          setTimeout(() => unsubscribe());
        },
        (e) => {
          reject(e);
          setTimeout(() => unsubscribe());
        });
    });
  }

  static async getCurrentUser(): Promise<FirebaseUser> {
    return FirebaseService.getApp().auth().currentUser || FirebaseService.getAuthState();
  }

  static async setupRemoteConfig(): Promise<void> {
    const remoteConfig = firebase.remoteConfig();
    remoteConfig.settings = getDefaultConfigSettings();
    remoteConfig.defaultConfig = getDefaultConfig();
    await remoteConfig.ensureInitialized();
  }

}
