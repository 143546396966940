import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AppoPlatformService {

  isNativePlatform = false;
  isIOSPlatform = false;
  isAndroidPlatform = false;
  isWebPlatform = false;
  isMobileWebPlatform = false;

  constructor(private platform: Platform) {
    this.isNativePlatform = this.platform.is('capacitor');
    this.isWebPlatform = !this.isNativePlatform;

    this.isIOSPlatform = this.platform.is('ios');
    this.isAndroidPlatform = this.platform.is('android');
    this.isMobileWebPlatform = this.platform.is('mobileweb');
  }
}
