import { gql } from 'apollo-angular';
import { UserFragment } from '@graphql/fragments/user';
import { UserBusinessesFragment } from '@graphql/fragments/user-businesses';
import { FavouriteBusinessFragment } from '@graphql/fragments/favourite-business';

export const FullUserFragment = gql`
  fragment FullUser on User {
    ...User
    businessesV2 {
      business {
        ...UserBusinesses
      }
      readOnly
    }
    favoriteBusinesses {
      ...FavouriteBusiness
    }
  }
  ${UserFragment}
  ${UserBusinessesFragment}
  ${FavouriteBusinessFragment}
`;
