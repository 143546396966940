import { AfterViewInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { supportsShadowDOM } from '@utils/browser';
import { Account } from '../../accounts/models/account';
import { AccountsService } from '../../accounts/services/accounts.service';
import { fastdomPromised } from '@utils/dom';
import { AccountType } from '@modules/accounts/models/account-type';
import { UserPermissionsService } from '@modules/accounts/services/user-permissions.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'menu-header',
  templateUrl: './menu-header.component.html',
  styleUrls: ['./menu-header.component.scss']
})
export class MenuHeaderComponent implements OnInit, AfterViewInit {

  // When changing this, also change it for the footer
  // in src/app/app.component.scss
  static borderRadiusSize = '28px';

  activeAccount$: Observable<Account>;

  constructor(private accountsService: AccountsService,
              protected userPermissionsService: UserPermissionsService) {
  }

  ngOnInit(): void {
    this.activeAccount$ = this.accountsService.getActiveAccount();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setHeaderStyle();
    }, 2000);
  }

  async setHeaderStyle() {
    try {
      if (!supportsShadowDOM()) {
        return;
      }
      const menuInner = await fastdomPromised.measure(
        () => document.querySelector('ion-menu')?.shadowRoot.querySelector('.menu-inner')
      );
      if (!menuInner) {
        throw new Error('Unable to find menuInner');
      }

      const borderRadiusPx = MenuHeaderComponent.borderRadiusSize;
      await fastdomPromised.mutate(() => menuInner.setAttribute('style', `border-radius:0px ${borderRadiusPx} ${borderRadiusPx} 0px`));

      const ionHeader = await fastdomPromised.measure(() => document.querySelector('ion-header ion-toolbar')?.shadowRoot.querySelector('.toolbar-background'));
      if (!ionHeader) {
        throw new Error('Unable to find ionHeader');
      }
      await fastdomPromised.mutate(
        () => ionHeader.setAttribute('style', `border-top-right-radius: ${borderRadiusPx};border-bottom-right-radius: ${borderRadiusPx};`)
      );
    } catch (e) {
      console.error(e);
      setTimeout(() => this.setHeaderStyle(), 5000);
    }
  }

  isBusinessAccount(accountType: AccountType): boolean {
    return accountType === AccountType.Business;
  }

}

