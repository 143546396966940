import { Params } from '@angular/router';
import { routerReducer as routerReducerFromLib, } from '@ngrx/router-store';

export const routerFeatureKey = 'router';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
  fragment: string;
}

export const routerReducer = routerReducerFromLib;

