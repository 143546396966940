export const supportsShadowDOM = () => {
  return !!document.head.attachShadow;
};

export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);


// Visibility
// Set the name of the hidden property and the change event for visibility
let hidden, visibilityChange;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else {
  // @ts-ignore
  if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
  } else
    // @ts-ignore
  if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
  }
}
export const isDocumentHidden = (): boolean => window.document[hidden];
export const VISIBILITY_CHANGE = visibilityChange;
