import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as Sentry from '@sentry/angular-ivy';
import { User } from '@models/user';
import { APPOINTIA_VERSION } from '@utils/version';
import posthog from 'posthog-js';

let sentryInstantiated = false;

@Injectable({providedIn: 'root'})
export class SentryErrorHandlerService implements ErrorHandler {
  constructor() {
    this.init();
  }

  init() {
    if (!sentryInstantiated && environment.production) {
      Sentry.init({
        dsn: environment.sentry.dsn,
        environment: 'production',
        release: APPOINTIA_VERSION,
        integrations: [
          new posthog.SentryIntegration(posthog, environment.sentry.organization, environment.sentry.projectId)
        ],
      });
      sentryInstantiated = true;
    }
  }

  handleError(error: any, msg = '') {
    if (msg) {
      console.error(msg);
    }
    console.error(error);
    Sentry.captureException(error?.originalError || error);

    if (this.isChunkError(error)) {
      console.error('Chunk load failed: ', error);
      window.location.reload();
    }
  }

  private isChunkError(err: Error): boolean {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    return !!err && chunkFailedMessage.test(err.message);
  }

  reportError(msg: string) {
    console.error(msg);
    Sentry.captureMessage(msg);
  }

  setUser(user: Partial<User>) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        email: user.email,
        username: user.name,
      });
    });
  }

  setDevice(platform: string) {
    Sentry.configureScope(scope => {
      scope.setExtra('platform', platform);
    });
  }

  setContext(name: string, context: Record<string, string | number | boolean>) {
    Sentry.setContext(name, context);
  }

}
