import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { SignInProvider } from '../../../pages/auth/models/sign-in-provider';
import { User } from '@models/user';

@Injectable({
  providedIn: 'root'
})
export class UserStatusService {

  constructor(private userService: UserService) {
  }

  hasEmailVerified(): Observable<boolean> {
    return this.userService.getUser().pipe(
      switchMap((user) => {
          console.log('Running hasEmailVerified()...');
          // If a User exists, then the user is valid
          if (user) {
            return of(true);
          }
          return from(this.userService.getFirebaseUser()).pipe(
            switchMap((firebaseUser) => from(this.userService.getUserSignedInProvider()).pipe(
                map((signInProvider: SignInProvider) => {
                  const signedInWithSocialProvider = signInProvider !== SignInProvider.SIGN_IN_EMAIL_AND_PWD;
                  if (signedInWithSocialProvider) {
                    return true;
                  }
                  // Signed in with email and password
                  return firebaseUser.emailVerified;
                })
              )
            )
          );
        }
      ),
    );
  }

  hasPhoneNumberVerified(): Observable<boolean> {
    return this.userService.getUser().pipe(
      tap(user => {
        console.log('Verifying user phone number:', user?.phoneNumber);
      }),
      // map(user => {
      //   // @ts-ignore
      //   if (window.testUser === 'true') {
      //     return {
      //       ...user,
      //       phoneNumber: undefined,
      //     };
      //   }
      //   return user;
      // }),
      // if there's an user it means the user exists in graphql
      map((user: User) => !!user && !!user.phoneNumber),
      tap(phoneNumberValid => {
        console.log('user has valid phone number: ', phoneNumberValid);
      }),
    );
  }
}
