import { gql } from 'apollo-angular';

export const UserBusinessesFragment = gql`
  fragment UserBusinesses on Business {
    businessId
    name
    pictureUrl
    timezone
    businessHandle
    email
    enabled
    mercadoPagoLinked
    resources {
      resourceId
      name
      pictureUrl
      enabled
    }
  }
`;
