import { Injectable } from '@angular/core';
import { SplashScreenHandler } from '@core/services/splash-screen/splash-screen-handler';
import { fastdomPromised } from '@utils/dom';


@Injectable({
  providedIn: 'root'
})
export class SplashScreenWebService implements SplashScreenHandler {

  async show(): Promise<void> {
    const splashScreenElement = await this.getSplashScreenElement();
    return fastdomPromised.mutate(() => {
      if (!splashScreenElement) {
        return;
      }
      splashScreenElement.style.display = 'block';
    });
  }

  async hide(): Promise<void> {
    const splashScreenElement = await this.getSplashScreenElement();
    return fastdomPromised.mutate(() => {
      if (!splashScreenElement) {
        return;
      }
      splashScreenElement.style.display = 'none';
    });
  }

  getSplashScreenElement(): Promise<HTMLElement | undefined> {
    return new Promise(resolve => {
      let splashScreenElement;
      fastdomPromised.measure(() => {
        splashScreenElement = window.document.getElementById('splash-screen');
        resolve(splashScreenElement);
      });
    });
  }

}
